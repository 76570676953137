import React, {useState, useEffect, useRef} from 'react'
import {Grid} from '@mui/material'
import AlertasActivas from './utils/AlertasActivas'
import FallasActivas from './utils/FallasActivas'


import './utils/css/Main_Alertas.css'

const Main_Alertas = ({alertas_activas, fallas_activas}) => {

    const [tipoSeleccionada, setTipoSeleccionada] = useState(null);
    const [alertaSeleccionada, setAlertaSeleccionada] = useState(null); // Estado para la alerta seleccionada
    const [fallaSeleccionada, setFallaSeleccionada] = useState(null); // Estado para la alerta seleccionada

    // const alertasFiltradas = alertas_activas?.filter(alerta =>  alerta?.rango !== 0);

    // const fallasSinAlerta = fallas_activas?.filter(falla => !falla?.alerta);
    const alertasFiltradas = alertas_activas?.filter(alerta =>  alerta?.rango !== 0);

    const fallasSinAlerta = fallas_activas?.filter(falla => !falla?.alerta);


    // const handleAlertClick = (alerta, tipo) => {
    //   setTipoSeleccionada(tipo);
  
    //   if (tipo === 'alerta') {
    //     setAlertaSeleccionada(prev => (prev === alerta ? null : alerta));
    //     setFallaSeleccionada(null); // Deseleccionar cualquier falla si se selecciona una alerta
    //   } else if (tipo === 'Falla') {
    //     setFallaSeleccionada(prev => (prev === alerta ? null : alerta));
    //     setAlertaSeleccionada(null); // Deseleccionar cualquier alerta si se selecciona una falla
    //   }
    // };

    const handleAlertClick = (alerta, tipo) => {
      setTipoSeleccionada(tipo);
    
      if (tipo === 'alerta') {
        // Solo actualiza si se selecciona una alerta distinta
        if (alertaSeleccionada !== alerta) {
          setAlertaSeleccionada(alerta);
          setFallaSeleccionada(null); // Deseleccionar cualquier falla
        }
      } else if (tipo === 'Falla') {
        // Solo actualiza si se selecciona una falla distinta
        if (fallaSeleccionada !== alerta) {
          setFallaSeleccionada(alerta);
          setAlertaSeleccionada(null); // Deseleccionar cualquier alerta
        }
      }
    };

    const getSensorName = (sensor) => {
        switch(sensor) {
            case 'O2': return     'Oxígeno';
            case 'DO2': return    'Saturación';
            case 'Vo': return     'Voltaje';
            case 'Io': return     'Corriente';
            case 'Press': return  'Presión';
            default: return sensor;
        }
    };
      
    const getFallaName = (sensor) => {
        switch(sensor) {
            case 'Hf_com': return 'Can Desconectada ';
            case 'Device_com': return 'Bomba Sin Comunicación';
            case 'Sensor_com': return 'Sensor sin Comunicación';
            case 'Device': return 'Falla de Bomba';
            // case 'sensor': return 'Falla de Sensor';
            case 'O2_com': return 'Sensor O2 sin Comunicación';
            default: return sensor;
        }
    };
    
    const getSensorFallaEstable = (falla) => {
        switch(falla) {
            case 'Hh_com': return 'Can Conectada ';
            case 'Device_com': return 'Bomba Conectado';
            case 'sensor_com': return 'Sensor Conectado';
            case 'device': return 'Sin Falla de Bomba';
            case 'sensor': return 'Sin Falla de Sensor';
            case 'O2_com': return 'Sensor O2 con Comunicación';
            default: return falla;
        }
    };

  // Agrupar las alertas por sensor usando useMemo para optimizar el rendimiento
  const groupedAlerts = React.useMemo(() => {
    return alertas_activas?.slice().reverse().reduce((acc, alerta) => {
      const variableParts = alerta?.variable?.split('-');
      // console.log("variable Parts", variableParts)
      // Extraer el sensor correctamente (índice 5)
      const sensor = variableParts[5]//(variableParts && variableParts.length >= 6) //? variableParts[-2] : 'Descosnocido';
      if (!acc[sensor]) acc[sensor] = []
      acc[sensor].push(alerta);
      return acc;
    }, {});
  }, [alertas_activas]);


  const [alturaMiddle, setAlturaMiddle] = useState('');
  const [alturaBottom, setAlturaBottom] = useState('');

  useEffect(() => {
    if (tipoSeleccionada === 'alerta') {
      if (alertaSeleccionada) {
        // Si una alerta está seleccionada
        setAlturaMiddle('40%');
        setAlturaBottom('38%');
      } else {
        // Solo tipo 'alerta' seleccionado pero ninguna alerta específica
        setAlturaMiddle('35%');
        setAlturaBottom('25%');
      }
    } else if (tipoSeleccionada === 'Falla') {
      if (fallaSeleccionada) {
        // Si una falla está seleccionada
        setAlturaMiddle('45%');
        setAlturaBottom('38%');
      } else {
        // Solo tipo 'Falla' seleccionado pero ninguna falla específica
        setAlturaMiddle('35%');
        setAlturaBottom('35%');
      }
    } else if (alertas_activas.length === 0) {
      // No hay alertas activas
      setAlturaBottom('90%');
      setAlturaMiddle('0%');
    } else if (fallas_activas.length === 0) {
      // No hay alertas activas
      setAlturaBottom('0%');
      setAlturaMiddle('90%');
    }
     else {
      // Ningún tipo seleccionado
      setAlturaMiddle('63%');
      setAlturaBottom('43%');
    }
  }, [tipoSeleccionada, alertaSeleccionada, fallaSeleccionada,alertas_activas, fallas_activas]);


  return (
    <Grid container justifyContent={'center'} height={'88vh'} width='95%' marginTop={'0.2vh'} border="solid 0.1vh #555">
         <Grid container justifyContent={'center'} alignContent={'flex-start'} item xs={12}  style={{ maxWidth: '14vw', width:'14vw', maxHeight:'75vh',  marginTop:'1vh'}}>
          <Grid container justifyContent={'center'} style={{ flex: '0 0 auto', width: '100%', marginBottom: '1vh' }}>
            {tipoSeleccionada === 'alerta' ?
              <AlertasActivas alertaSeleccionada={alertaSeleccionada} fallaSeleccionada={fallaSeleccionada} onClose={() => { setAlertaSeleccionada(null); setTipoSeleccionada(null); }} />
            :
              <FallasActivas fallaSeleccionada={fallaSeleccionada} onClose={() => { setFallaSeleccionada(null); setTipoSeleccionada(null); }}  />
            }

          </Grid>
          <Grid container justifyContent={'center'}  style={{height: alturaMiddle,overflowY:'hidden'}}>
            {alertas_activas.length > 0 && 
              <Grid container justifyContent={'center'} alignContent={'center'} alignItems={'center'} item xs={11} style={{fontSize:'1vw', color:'#C3C3C3',  flex: '0 0 auto', height:'4vh',}}>
                <strong> Alertas Activas : {alertasFiltradas?.length} </strong>
              </Grid>
            }
            <Grid container justifyContent={'center'} style={{ flex: '1 1 auto',height:'80%',  overflowY: 'auto',marginBottom: '1vh'}}>
              {groupedAlerts && Object.entries(groupedAlerts).map(([sensor, alerts]) => (
                <div key={sensor} style={{ marginBottom: '0vh' }}>
                  {/* Título del Sensor */}
                  <h1 variant="h6"  style={{fontSize:'1.2vw', }}>
                    {getSensorName(sensor)}
                  </h1>

                  {/* Lista de Alertas para el Sensor */}
                  {alerts.map((alerta, alertaIndex) => {
                    // const isSelected = alerta === alertaSeleccionada;
                    const isSelected = alertaSeleccionada && alerta._id === alertaSeleccionada._id;
                    const variableParts = alerta?.variable?.split('-') || [];
                    const sensorName = variableParts[2] //variableParts[2] || 'Desconocido';
                    const fechaParts = alerta?.fecha?.split('-') || [];
                    const hora = fechaParts.length >= 4 ? fechaParts[3] : '00:00:00';
                    const tipo = 'alerta';
                    // console.log(alerta)
                    return (
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        // style={{ height: '4.5vh' }}
                        item
                        xs={12}
                        key={alerta._id}
                      >
                        <div
                          role="alert"
                          style={{
                            ...alertStyleBase,
                            // backgroundColor: '#7f1d1d', // red-100 7f1d1d
                            backgroundColor:  isSelected ? 'rgba(255, 0, 0, 0.57)': '#7f1d1d',
                            borderColor: '#fee2e2',     // red-500
                            color: '#fee2e2',           // red-900
                            width:'13vw',
                            marginTop:'0.5vh',
                            transform: isSelected ? 'scale(1.02)' : 'scale(0.95)', // Cambia la escala si está seleccionada

                          }}
                          onClick={() => handleAlertClick(alerta, tipo)}
                        >
                          <svg
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ ...iconStyleBase, color: '#dc2626' }} // red-600
                          >
                            <path
                              d="M13 16h-1v-4h1m0-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              strokeWidth={2}
                              strokeLinejoin="round"
                              strokeLinecap="round"
                            />
                          </svg>
                          <p style={textStyleBase}>{sensorName} - {hora}</p>
                        </div>
                      </Grid>
                    );
                  })}
                </div>
              ))}

            </Grid>
          </Grid>
          <Grid container justifyContent={'center'} style={{height: alturaBottom, overflowY:'hidden'}}>

          <Grid container justifyContent={'center'} >
            {fallas_activas.length > 0 && 
              <Grid container justifyContent={'center'} alignContent={'center'} alignItems={'center'} item xs={11} style={{fontSize:'1vw', color:'#C3C3C3', marginBottom:'1vh',  height:'4vh'}}>
                <strong>Fallas Activas: {fallasSinAlerta?.length}</strong>
                {/* Detalles de la Alerta Seleccionada */}
              </Grid>
            }
            </Grid>
            <Grid container justifyContent={'center'} style={{ flex: '1 1 auto', height:tipoSeleccionada != 'alerta' ?'80%':'70%',  overflowY: 'auto',marginBottom: '1vh',}}>
              {fallas_activas?.map((alerta, alerta_id)=>{
                // const isSelected = alerta === fallaSeleccionada; // Verifica si la alerta actual es la seleccionada
                const isSelected = fallaSeleccionada && alerta._id === fallaSeleccionada._id;
                const [modulo, hf, jaula, bomba, aviot,sensor] = alerta?.variable?.split('-') ? alerta?.variable?.split('-') : '';
                const [año, mes, dia, hora ] = alerta?.fecha?.split('-') ? alerta?.fecha?.split('-') : '';
                const tipo = 'Falla'
                return(    
                    <div
                      role="alert"
                      style={{
                        ...alertStyleBase,
                        // backgroundColor: '#7f1d1d', // red-100 7f1d1d
                        backgroundColor:  isSelected ? 'rgba(255, 0, 0, 0.57)': '#7f1d1d',
                        borderColor: '#fee2e2',     // red-500
                        color: '#fee2e2',           // red-900
                        width:'13vw',
                        marginTop:'0.5vh',
                        transform: isSelected ? 'scale(1.02)' : 'scale(0.95)', // Cambia la escala si está seleccionada
                      }}
                      onClick={() => handleAlertClick(alerta, tipo)}
                    >
                      <svg
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ ...iconStyleBase, color: '#dc2626' }} // red-600
                      >
                        <path
                          d="M13 16h-1v-4h1m0-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          strokeWidth={2}
                          strokeLinejoin="round"
                          strokeLinecap="round"
                        />
                      </svg>
                      <p style={textStyleBase}>
                        {jaula} - {hora} <br/>
                        { alerta.alerta ? getSensorFallaEstable(sensor) : getFallaName(sensor) }
                      
                      </p>
                    </div>
                )
              })}
            </Grid>
          </Grid >
        </Grid >
    </Grid>
  )
}

export default Main_Alertas;

const alertStyleBase = {
  padding: '0.5vw',
  borderRadius: '0.5vw',
  display: 'flex',
  alignItems: 'center',
  borderLeftWidth: '0.4vw',
  transition: 'all 0.3s ease-in-out',
  transform: 'scale(1)'
};

const iconStyleBase = {
  height: '1.25vw',
  width: '1.25vw',
  flexShrink: 0,
  marginRight: '0.5vw'
};

const textStyleBase = {
  fontSize: '0.7vw',
  fontWeight: 600,
  margin: 0
};
