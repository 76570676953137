import React from 'react';
import { Grid, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';



const Fallas = ({fallaSeleccionada, onClose}) => {
    const getSensorName = (sensor) => {
    switch(sensor) {
      case 'Hf_com': return 'Can Desconectada ';
      case 'Device_com': return 'Bomba Sin Comunicación';
      case 'Sensor_com': return 'Sensor sin Comunicación';
      case 'Device': return 'Falla de Bomba';
      // case 'sensor': return 'Falla de Sensor';
      case 'O2_com': return 'Sensor O2 sin Comunicación';
      default: return sensor;
    }
    };

    const getSensorFallaEstable = (falla) => {
      switch(falla) {
        case 'Hf_com': return 'Can Conectada ';
        case 'Device_com': return 'Bomba Conectado';
        case 'Sensor_com': return 'Sensor Conectado';
        case 'Device': return 'Sin Falla de Bomba';
        case 'sensor': return 'Sin Falla de Sensor';
        case 'O2_com': return 'Sensor O2 con Comunicación';
        default: return falla;
    }
    };

    const getBackgroundColor = (alerta) => {
      switch (alerta) {
      case false:
      return '#9c282a';
      case true:
      return '#296f4d';
      default:
      return 'transparent';
      }
      };
    

    const [año, mes, dia, hora ] = fallaSeleccionada?.fecha?.split('-') ? fallaSeleccionada?.fecha?.split('-') : '';
    const [modulo, jaula, bomba,can,sensor,name] = fallaSeleccionada?.variable?.split('-') ? fallaSeleccionada?.variable?.split('-') : '';

    if (!fallaSeleccionada) {
        return (
          <Grid container justifyContent={'center'} alignContent={'center'} item xs={11} 
              style={{backgroundColor:'#0d0d0d', borderRadius:'0.5vw', marginBottom:'1vh', border:'solid 0.1vh #7f0000', fontSize:'1vw', color:'#ffffff', marginBottom:'1vh',  height:'6vh'}}>
              <strong>Detalle Alerta</strong>
          </Grid>
          ) 
    }

    // console.log('1-HF1-101_102_103_104-VA1_VA2_VA3_VA4-3-Press',fallaSeleccionada)

    
  return (
    <Grid container justifyContent={'center'} alignContent={'center'} item xs={11} style={{ width:'15vw',zIndex:6, backgroundColor:'#0d0d0d', borderRadius:'0.5vw', marginBottom:'1vh', border:'solid 0.1vh #7f0000'}}>
      <IconButton onClick={()=>{onClose()}} style={{ left:'9.5vw', top:'0.5vh', height:'1.2vw', width:'1.2vw'}}>
        <ClearIcon style={{fontSize:'1vw'}}/>
      </IconButton>
      <Grid container justifyContent={'center'} alignContent={'center'} alignItems={'center'} item xs={9} style={{fontSize:'0.8vw', color:'#ffffff', marginBottom:'-2vh',  height:'6vh'}}>
        <strong style={{fontSize:'0.8vw'}}>Detalles de la Alerta</strong>
        {/* Detalles de la Alerta Seleccionada */}
      </Grid>
      <Grid container justifyContent={'center'} alignContent={'flex-start'} item xs={11} style={{fontSize:'0.75vw', height:'3vh'}}>
        <Grid container justifyContent={'center'} alignContent={'center'} style={{borderRadius:'0.2vw', height:'2vh',backgroundColor:getBackgroundColor(fallaSeleccionada.alerta)}}>
        { fallaSeleccionada?.alerta ? getSensorFallaEstable(name) : getSensorName(name)} 
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={6} style={{fontSize:'0.7vw', height:'3vh'}}>
          Fecha
        </Grid>
        <Grid item xs={6} style={{fontSize:'0.7vw', height:'3vh'}}>
          {dia} - {mes} - {año}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={6} style={{fontSize:'0.7vw', height:'3vh'}}>
          Hora
        </Grid>
        <Grid item xs={6} style={{fontSize:'0.7vw', height:'3vh'}}>
          {hora}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={6} style={{fontSize:'0.7vw', height:'3vh'}}>
          Módulo
        </Grid>
        <Grid item xs={6} style={{fontSize:'0.7vw', height:'3vh'}}>
          {modulo}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={6} style={{fontSize:'0.7vw', height:'3vh'}}>
          Equipos
        </Grid>
        <Grid item xs={6} style={{fontSize:'0.7vw', height:'3vh'}}>
          {bomba}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Fallas