import React, { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import { Box } from '@mui/material';
import '../assets/css/AlertaGenerador.css';
// import AlertGenerator from '../assets/img/generador.png';

const AlertaGenerador = ({ parentRef, logo,  title ,top}) => {
  const [position, setPosition] = useState({ x: 10, y: top });
  const [mostrarBorde, setMostrarBorde] = useState(true);

  // Cargar posición desde localStorage al montar el componente
  useEffect(() => {
    const savedPosition = localStorage.getItem('alertaGeneradorPosition');
    if (savedPosition) {
      setPosition(JSON.parse(savedPosition));
    }
  }, []);

  // Guardar posición en localStorage cuando cambie
  useEffect(() => {
    localStorage.setItem('alertaGeneradorPosition', JSON.stringify(position));
  }, [position]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMostrarBorde(false);
    }, 3000); // 3 segundos

    return () => clearTimeout(timer);
  }, []);
  

  // Manejar movimiento con el teclado
  const handleKeyDown = (e) => {
    const step = 10; // Paso de movimiento en píxeles
    let newX = position.x;
    let newY = position.y;

    switch (e.key) {
      case 'ArrowUp':
        newY = Math.max(0, position.y - step);
        break;
      case 'ArrowDown':
        newY = Math.min(parentRef.current.clientHeight - 100, position.y + step); // 100 es una altura estimada
        break;
      case 'ArrowLeft':
        newX = Math.max(0, position.x - step);
        break;
      case 'ArrowRight':
        newX = Math.min(parentRef.current.clientWidth - 100, position.x + step); // 200 es una anchura estimada
        break;
      default:
        break;
    }

    setPosition({ x: newX, y: newY });
  };

   // Función para renderizar el título Error Contacto Seco al Generador
   const renderTitle = () => {
    if (title === 'Sin Comunicación con Generador') {
      const partes = title.split(' con ');
      return (
        <>
          <span>{partes[0]}</span>
          <br />
          <span> con {partes[1]}</span>
        </>
      );
    } else if (title === 'Error Contacto Seco al Generador') {
      const partes = title.split(' al ');
      return (
        <>
          <span>{partes[0]}</span>
          <br />
          <span> al {partes[1]}</span>
        </>
      );
    }
    return title;
  };
  const [scale, setScale] = React.useState(1);

  const initialSize = 575;
  return (
    <Rnd
      default={{
        x: position.x,
        y: position.y,
        width: initialSize,
        minWidth:initialSize,
        height: window.innerWidth < 2000 ? window.innerHeight * 0.35 : 'auto',
      }}
      bounds="window"
      minWidth={initialSize*.5}  // Impide que el ancho sea menor que el valor inicia
      maxWidth={initialSize*.67}  // Impide que el ancho sea menor que el valor inicia
      minHeight={initialSize*.28}  // Impide que el ancho sea menor que el valor inicia
      lockAspectRatio={1/0.3}
      onResize={(e, direction, ref, delta, position) => {
        const newWidth = ref.offsetWidth;
        setScale((newWidth / initialSize));
      }}
      enableResizing={false}
      //dragHandleClassName='alert-generador'
      //dragHandleClassName="drag-handle"
      style={{ zIndex: 1000 }} // Asegura que la alerta esté por encima de otros elementos
    >
      <Box
        className="alert-generador"
        // sx={{...floatMenuStyle,
        //   '&:hover': {
        //     // backgroundColor: 'rgba(0, 0, 0, 0.5)',
        //       },
        // }}
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
          // width: initialSize, // Se fija el ancho base para la escala
          // minWidth:initialSize,
        }}
        // onKeyDown={handleKeyDown}
        // tabIndex="0" // Hace que el div sea enfocável para recibir eventos de teclado
        // role="dialog" // Describe el propósito del componente
        // aria-label="Alerta de Generador Apagado" // Proporciona una descripción accesible
      >
        <div className="drag-handle">
          <span className="title-generador">{renderTitle()}</span>
          
        </div>
        <img src={logo}   className={`icon-generador ${mostrarBorde ? 'border-illuminada' : ''}`}  alt="Generador" />
      </Box>
    </Rnd>
  );
};

export default AlertaGenerador;