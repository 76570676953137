import React, { useState} from 'react';
import { Grid } from '@mui/material';
import '../assets/css/Umbrales.css'
const Umbrales = ({ configuracionUmbrales }) => {

    const transformMessage = (message) => {
      // Dividir el mensaje en base a los saltos de línea
      const lines = message.split('\n');
      
      // Mapear cada línea a un componente
      return (
        <>
          {lines.map((line, index) => {
            // Buscar palabras o frases entre **
            const boldMatches = line.match(/\*\*(.*?)\*\*/g);
    
            if (boldMatches) {
              boldMatches.forEach(match => {
                const boldText = match.replace(/\*\*/g, ''); // Remover los ** para obtener el texto en negrita
                line = line.replace(match, `<strong style="font-size:01vh; "> ${boldText} </strong>`); // Reemplazar en la línea original
              });
            }
    
            // Usar un elemento de texto enriquecido para el contenido HTML
          return <p key={index} dangerouslySetInnerHTML={{ __html: line }} style={{marginRight:'0.5vw'}} />;          })}
        </>
      );
    };

  const nombresUmbrales = [
    { "nombre": "Inicio Inyección O**2**" },
    { "nombre": "Voltaje" },
    { "nombre": "Corriente" },
    { "nombre": "Presión O**2**" },
  ];
      
    return (
    // <Grid container justifyContent="center" alignContent={'center'} alignItems={'center'} marginTop='1vh'>
      <Grid container justifyContent="center"  alignContent={'center'} alignItems={'center'} item xs={12}  >
        {configuracionUmbrales &&
          configuracionUmbrales.map((umbrales, index) => {
            const nombreUmbrales = nombresUmbrales[index].nombre;
            return(
              // <Grid container justifyContent="center"   alignContent={'center'} alignItems={'center'} item xs={2} key={index} style={{ minWidth: '12vw',maxWidth:"12vw", marginLeft: '1vh', fontSize: '0.85vw', border: 'solid 0.01vh #555', borderRadius:"1vh",backgroundColor:"#171717"}}>
              //   <Grid container justifyContent="center" alignContent={'center'} alignItems={'center'} item xs={12} style={{ height:"5vh", backgroundColor:"#171717", fontSize:"1vw",borderTopLeftRadius:"1vh" ,borderTopRightRadius:"1vh" ,borderBottom:" solid 0.01vh #555"}}>
              //     <div style={{ fontSize:"1vw"}}>
              //       {transformMessage(nombreUmbrales)}
              //     </div>
              //       {umbrales.tipo === "PRESS"  && <p style={{fontSize:"0.7vw", marginLeft:"0.5vw"}}>[ bar ]</p> }
              //       {umbrales.tipo === "O2"  && <p style={{fontSize:"0.7vw",    marginLeft:"0.5vw"}}>[ mg/L ]</p> }
              //       {umbrales.tipo === "Io" && <p style={{fontSize:"0.7vw",     marginLeft:"0.5vw"}}>[ A ]</p> }
              //       {umbrales.tipo === "Vo" && <p style={{fontSize:"0.7vw",     marginLeft:"0.5vw"}}>[ V ]</p> }
              //   </Grid>
              //   {/* {umbrales.tipo !== "O2" && umbrales.Min !== undefined &&  ( */}
              //     <Grid container justifyContent="center" item xs={6}  style={{  borderRight: 'solid 0.01vh #555'}}>
              //       <Grid container justifyContent="center" item xs={12}>
              //         <Grid container justifyContent="center" alignContent='center' item xs={2} style={{fontSize:"1vw", marginLeft:"1.3vw"}}>
              //           Min
              //         </Grid>
              //         <Grid container justifyContent="center" alignContent='center' item xs={8} style={{padding:'0.7vh 0.7vw'}}>
              //           {umbrales.Min}
              //         </Grid>
              //       </Grid>
              //     </Grid>
              //   {/* // )}                       */}
              //   <Grid container justifyContent="center" item xs={6}>
              //     <Grid container justifyContent="center" item xs={12} style={{backgroundColor:"#171717",borderBottomLeftRadius:"1vh" ,borderBottomRightRadius:"1vh"}}>
              //       <Grid container justifyContent="center" alignContent='center' item xs={2} style={{fontSize:"1vw", marginLeft:"1.3vw",padding:'0.7vh 0.7vw' }}>
              //       {/* {umbrales.tipo !== "O2" ? "Max" : "On"} */}
              //         {"Max" }
              //       </Grid>
              //       <Grid container justifyContent="center" alignContent='center' item xs={8}>
              //         {umbrales.Max}
              //       </Grid>
              //     </Grid>
              //   </Grid>
              // </Grid>
              <Grid container justifyContent="center" alignItems="center" item xs={2} key={index} className="umbrales-container">
                <Grid container justifyContent="center" alignItems="center" item xs={12} className="umbrales-header" >
                  <div className="header-text">
                    {transformMessage(nombreUmbrales)}
                  </div>
                  {umbrales.tipo === "PRESS" && (
                    <p className="type">[ bar ]</p>
                  )}
                  {umbrales.tipo === "O2" && (
                    <p className="type">[ mg/L ]</p>
                  )}
                  {umbrales.tipo === "Io" && (
                    <p className="type">[ A ]</p>
                  )}
                  {umbrales.tipo === "Vo" && (
                    <p className="type">[ V ]</p>
                  )}
                </Grid>

              <div className="umbrales-body">
                <Grid container justifyContent="center" item xs={6} className="umbrales-min">
                  <Grid container justifyContent="center" item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" item xs={12} className="label" >
                      Min: {umbrales.Min}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container justifyContent="center" item xs={6} className="umbrales-max">
                  <Grid container justifyContent="center" item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" item xs={12} className="label">
                      Max: {umbrales.Max}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            )
        })}
      </Grid>
    // </Grid>
  );
};

export default Umbrales;

