import React from 'react';
import './assets/css/Status.css'; // Importa la hoja de estilos

const Status = ({bomba, controlType }) => {
// console.log('bopmba',bomba)

  let mensaje = null;

    // * PS
    // if (bomba.O2 < 0 || bomba.O2 === null) {
    //   mensaje = 'Promedio Sensores Cercanos';
    // } else
     if (controlType === 'PS') {
      if (bomba.O2 < bomba.SetPoint && bomba.Device_Modbus_Status === false) {
        mensaje = "BOMBA DESCONECTADA";
      } else if (bomba.InStop === "1") {
        mensaje = "Parada de Emergencia";
      } else if (bomba.Status === 0) {
        if (bomba.InStart === "1" && bomba.elapsed_time >= 60) {
          mensaje = "Falla de Alimentación";
        } else {
          mensaje = null;
        }
      } else if (bomba.Status === 13) {
        mensaje = "Alerta";
      } else {
        mensaje = null;
      }
    } else if (controlType === 'VDF') {
      if (bomba.O2 < bomba.SetPoint && bomba.Device_Modbus_Status === false) {
        mensaje = "BOMBA DESCONECTADA";
      } else if (bomba.Fault !== '0') {
        mensaje = bomba.Fault;
      } else if (bomba.Fault === '0') {
        mensaje = null;
      }
    }
  return (
    <>
      {mensaje && (
        <div className="alerts-container">
          <div role="alert" className={`alert-status ${bomba.O2 < 0 || bomba.O2 === null ? 'alert-warning': 'alert-error'}`}>
            <svg
              stroke="currentColor"
              viewBox="0 0 24 24"
              fill="none"
              className="icon icon-error"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 16h-1v-4h1m0-4h.01M21 12a9 9 0 
                11-18 0 9 9 0 0118 0z"
                strokeWidth="2"
                strokeLinejoin="round"
                strokeLinecap="round"
              ></path>
            </svg>
            <p className="alert-text-alias">{bomba.alias}</p>

            <p className="alert-text">{mensaje}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Status;
