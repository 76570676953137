
import React from "react";
import { Grid, Tooltip, Button, ButtonGroup } from "@mui/material";
import PowerIcon from '@mui/icons-material/Power';
import BatterySaverIcon from '@mui/icons-material/BatterySaver';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import './utils/css/DetalleCan.css'

const DetalleCan = ({
  can,
  StyleTooltip,
  idx,
  translate
}) => {
  // console.log('can.source',can.source)
  // console.log('can',can)
  return (
        <Grid container className="grid-container-can" item xs={12} key={idx}>
          <Grid container className={`can-info ${can.source === false ? 'error' : ''}`} item xs={7} key={`can-info-${idx}`}>
            <Grid container item className="can-info-section border-right" xs={3} >
              <h1 className="can-info-title">
            { ( can?.voltaje === null || isNaN(can?.voltaje)) ? "- -" :  can?.voltaje?.toFixed(2) +'V'} 

                {/* {can.voltaje.toFixed(2)} V */}
              </h1>
            </Grid>

            <Grid container item className="can-info-section" xs={3} >
              <h1 className="can-info-title">
              { (can?.corriente === null || isNaN(can?.corriente) ) ? "- -" :  can?.corriente?.toFixed(2) +'A'} 

                {/* {can.corriente.toFixed(2)} A */}
              </h1>
            </Grid>

            <Grid container item className="can-info-section border-left" xs={3} >
              <h1
                className={`can-info-title ${
                  can.presion < 0 ? 'small' : ''
                }`}
              >
                {can.presion < 0 ? translate('sensor_failure') : `${ (can?.presion ===null || isNaN(can?.presion)) ? "- -" :  can?.presion?.toFixed(2)} bar`}
              </h1>
            </Grid>

            <Grid container item className="can-info-section border-left" xs={3}>
              {/* <h1 className="can-info-title normal">
                {`${can.sal} ppt`}
              </h1> */}
              <h1
                className={`can-info-title ${ can.sal < 0 ? 'small' : '' }`}
              >

                {can.sal < 0 ? translate('sensor_failure') : `${( can?.sal === null || isNaN(can?.sal)) ? "-- " :  can?.sal?.toFixed(2)} ppt`}
              </h1>
            </Grid>
          </Grid>

        <Grid container item xs={3} className={`can-button-group`}>
          <ButtonGroup variant="text" color="inherit">
            <Tooltip title={can.estado ? translate('tarjeta_controladora_on') : translate('tarjeta_controladora_off')}>
              <Button className={`can-button ${can.source === false ? 'error' : ''}`}>
                <FiberManualRecordIcon style={{ color: can.estado ? "#296f4d" : "#9c282a" }} className="can-button-icon" />
              </Button>
            </Tooltip>
            <Tooltip title={can.source ? translate('conect_fuente') : translate('conect_battery')}>
              <Button className={`can-source-button ${can.source === false ? 'error' : ''}`}>
                {can.source ? <PowerIcon className="can-button-icon" /> : <BatterySaverIcon className="can-button-icon" />}
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Grid>
      </Grid>

  );
};

export default DetalleCan;
