import React from 'react';
import './css/LastMeasurement.css';

const LastMeasurement = ({ ultimaHora }) => {
  const currentTime = ultimaHora
  //"2024-11-28 18:59:02";

  const time = currentTime?.split(" ")?.[1] ; // Extraer la hora (HH:mm)

  // Formatear la fecha completa (Día, DD de Mes)
  const rawDate = new Date(currentTime).toLocaleDateString("es-ES", {
    weekday: "long",
    day: "numeric",
    month: "long",
  });

  // Capitalizar la primera letra de cada palabra (día y mes)
  const capitalizedDate = rawDate
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return (
    <div className="clock-container">
      <div className="last-measurement">
        <div>Última</div>
        <div>Medición</div>
        <div></div>
      </div>
      <div className="main-clock">
        {/* <div>{capitalizedDate}</div> */}
          <div className="days-row">
           <div className="">{capitalizedDate}</div>
         </div>
        <div className="time-display">{time}</div>
      </div>
    </div>
  );
};

export default LastMeasurement;
