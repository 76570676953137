// import React, { useState, useEffect, useRef } from 'react';
// import {  ButtonGroup, Fab , Button,IconButton, Grid, Box } from '@mui/material';
// import Main_Bombas from '../component/layout/Main_Bombas';
// import { fetchAlertas, fetchFallas } from '../utils/ApiServer';
// // import NavigationIcon from '@mui/icons-material/Navigation';
// // import AnnouncementIcon from '@mui/icons-material/Announcement';
// // import ErrorIcon from '@mui/icons-material/Error';
// // import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// // import ReportProblemIcon from '@mui/icons-material/ReportProblem';
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import Main_Alertas from '../component/layout/Main_Alertas';
// import AlertaGenerador from '../component/AlertaGenerador';
// import SinSeñal from '../assets/img/sin señal.png'
// import Generador from '../assets/img/generador.png'
// import Umbrales from '../component/Umbrales';
// import PaginationButtons from '../component/PaginationButtons';

// import '../assets/css/Home.css'
// const Home = ({center_data,centroSeleccionado,translate,mostrarAlertas, controlType, modo}) => {

// const [modulosPorPagina, setModulosPorPagina] = useState (2);
// const [alertas, setAlertas] = useState([]);
// const [fallas, setFallas] = useState([]);

//   const [page, setPage] = useState(1);
//   const totalModules = center_data?.modulos?.length || 0;

//   const plc_status = center_data?.generadores?.plc_status
//   const hf_status  = center_data?.generadores?.hf_status

//   const [mostrarUmbrales, setMostrarUmbrales] = useState(false);


//   const handleClick = () => {
//     setMostrarUmbrales(!mostrarUmbrales);
//   };


//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };
//   const handleNextPage = () => {
//     setPage((prevPage) => Math.min(prevPage + 1, Math.ceil(totalModules / modulesPerPage)));
//   };

//   const handlePrevPage = () => {
//     setPage((prevPage) => Math.max(prevPage - 1, 1));
//   };

//   const modulesPerPage = 1;
//   const startIndex = (page - 1) * modulesPerPage;
//   const endIndex = startIndex + modulesPerPage;
//   // * alertas Activas
//   useEffect(() => {
//           const fetchData = async () => {
//               const loggedIn = true
//               const data = await fetchAlertas(loggedIn);
//               if (Array.isArray(data)) {
//                   setAlertas(data);
//                   // console.log('data alertas',data)
//               } else {
//                   // Manejar el caso en el que no se recibe un array
//                   console.error('fetchAlertas no devolvió un array válido:', data);
//               }
//           };
      
//           // Ejecutar la función inmediatamente al cargar el componente
//           fetchData();
      
//           // Configurar el intervalo para ejecutar la función cada minuto
//           const interval = setInterval(fetchData, 30000);
      
//           // Limpiar el intervalo al desmontar el componente
//           return () => clearInterval(interval);
//       }, []);
//   // * fallas Activas
//   useEffect(() => {
//     const fetchData = async () => {
//         const loggedIn = true
//         const data = await fetchFallas(loggedIn);
//         if (Array.isArray(data)) {
//             setFallas(data);
//             // console.log('data fallas',data)

//         } else {
//             // Manejar el caso en el que no se recibe un array
//             //console.error('fetchAlertas no devolvió un array válido:', data);
//         }
//     };

//     // Ejecutar la función inmediatamente al cargar el componente
//     fetchData();

//     // Configurar el intervalo para ejecutar la función cada minuto
//     const interval = setInterval(fetchData, 30000);

//     // Limpiar el intervalo al desmontar el componente
//     return () => clearInterval(interval);
//   }, []);


//    // Crear una referencia para el contenedor principal
//    const mainContainerRef = useRef(null);

// // console.log('controlType home',controlType)
//   return (
//     <Grid container justifyContent="center" ref={mainContainerRef} style={{ width:"100vw", height:"90vh", overflow:'hidden', }}>

//         { plc_status === 100 ?  <AlertaGenerador parentRef={mainContainerRef} top={10} logo={Generador} title={'Alerta en Generador'} /> : "" }  
//         { plc_status === -1 ?  <AlertaGenerador parentRef={mainContainerRef} top={10} logo={SinSeñal} title={'Sin Comunicación con Generador'} /> : "" }  
//         { hf_status === -2 ?  <AlertaGenerador parentRef={mainContainerRef}  top={200} logo={SinSeñal} title={'Error Contacto Seco al Generador'} /> : "" }  

//       <Grid container justifyContent={'center'}  item xs={ alertas?.length === 0 && fallas?.length === 0 ? 12 : 10}>{/*item xs={mostrarAlertas ? 10 : 12} style={{ maxWidth: mostrarAlertas ? '85vw' :'95vw'}}>*/}
//         {centroSeleccionado && center_data && center_data.modulos && (
//           <Grid container justifyContent="center">
//               {center_data && center_data.modulos && center_data.modulos.slice(startIndex, endIndex).map((modulo, index) => {
//                 // console.log('%c modulo:', 'color: red; font-size:30px ', modulo);
//                 return(
//                   <Grid container justifyContent="center" key={modulo.id}  style={{
//                       // maxWidth:  mostrarAlertas ? '85vw' :'95vw',
//                       minWidth:'100%',
//                       maxWidth:'100%',
//                       // minWidth:'83vw',
//                       // maxWidth:'83vw',
//                       height: '88.1vh', // Adjust the height based on the number of modules
//                       // height: isSingleModule ? '78vh' : '39vh', // Adjust the height based on the number of modules
//                       // width: mostrarAlertas ? '85vw' :'95vw',
//                       flexGrow: 1,
//                       // backgroundColor: '#0d0d0d' ,
//                       flexDirection: 'column',
//                       alignItems: 'center', // Centrar verticalmente
//                       justifyContent: 'center', // Centrar horizontalmente
//                       marginTop: '0.45vh',
//                       border:'solid 0.1vh #555555',
//                       // borderRadius:'1vh',
//                       // marginLeft:"11vw",
                      
                      
//                   }}>
//                     <Grid container justifyContent="center" alignContent='center' style={{ height:'5vh',}}>
//                       <Grid container justifyContent={'center'} item xs={3} style={{  }}>
//                         <IconButton onClick={handlePrevPage} disabled={page === 1}>
//                           <KeyboardArrowLeftIcon style={{fontSize:'1.2vw'}}/>
//                         </IconButton>
//                       </Grid>
//                       <Grid  container justifyContent={'center'} item xs={3} >
//                         <h1 style={{fontSize:"3vh"}}> {translate('modulo')} {modulo.id}</h1>
//                       </Grid>
//                       <Grid container justifyContent={'center'} item xs={3} style={{  }}>
//                         <IconButton onClick={handleNextPage} disabled={page === Math.ceil(totalModules / modulesPerPage)}>
//                           <KeyboardArrowRightIcon style={{fontSize:'1.2vw'}}/>
//                         </IconButton>
//                       </Grid>
//                     </Grid>
//                     <Grid container justifyContent="center" style={{  height:'82vh', overflowY:'auto', overflowX:'hidden'}} >
//                         <Main_Bombas translate={translate} modulo={modulo} indiceModulo={index} modulosPorPagina={modulosPorPagina} controlType={controlType} modo={modo}/>
//                     </Grid>
                  
//                   </Grid>
//                 )
//               })}
//           </Grid>
//         )}
//       </Grid>
//       <Grid className='contenedor-Umbrales' onClick={handleClick}>
//         <Grid container className="contenedor-Umbrales" >
//           <div className={`tarjeta ${mostrarUmbrales ? '' : 'sin-umbrales'}`}>
//             <div className="tarjeta-header">Ver Umbrales</div>
//             <div className={`tarjeta-content ${mostrarUmbrales ? 'mostrar' : ''}`}>
//             <Umbrales configuracionUmbrales={center_data.umbrales}/>
//             </div>
//           </div>
//         </Grid>
//       </Grid>
//        {/* Alertas */}
//        { (fallas?.length > 0 || alertas?.length > 0) && 
//           <Grid container justifyContent={'center'} item xs={1.9}>
//             <Main_Alertas 
//               alertas_activas={alertas} 
//               fallas_activas={fallas} 
//             />
//           </Grid>
//         }
//    </Grid>
//   )
// }

// export default Home
import React, { useState, useEffect, useRef } from 'react';
import { Grid,Paper} from "@mui/material";
import { fetchAlertas, fetchFallas } from '../utils/ApiServer';

import Main_Bombas from '../component/layout/Main_Bombas';
import Umbrales from '../component/Umbrales';
import Main_Alertas from '../component/layout/Main_Alertas';
import AlertaGenerador from '../component/AlertaGenerador';
import SinSeñal from '../assets/img/sin señal.png'
import Generador from '../assets/img/generador.png'

// import '../assets/css/loading.css'

const Home = ({center_data,centroSeleccionado,translate,mostrarAlertas, controlType, modo}) => {

  const isSingleModule = center_data?.modulos?.length === 1;
  const cantidad_modulos = center_data?.modulos?.length
  const [currentPage, setCurrentPage]                   = useState (1);
  const [modulosPorPagina, setModulosPorPagina]         = useState (2);

  const plc_status = center_data?.generadores?.plc_status
  const hf_status  = center_data?.generadores?.hf_status

  const [alertas, setAlertas] = useState([]);
  const [fallas, setFallas] = useState([]);

  // Crear una referencia para el contenedor principal
  const mainContainerRef = useRef(null);
  // console.log('plc_status',plc_status)
  // console.log('hf_status',hf_status)

    // * alertas Activas
  useEffect(() => {
          const fetchData = async () => {
              const loggedIn = true
              const data = await fetchAlertas(loggedIn);
              if (Array.isArray(data)) {
                  setAlertas(data);
                  // console.log('data alertas',data)
              } else {
                  // Manejar el caso en el que no se recibe un array
                  console.error('fetchAlertas no devolvió un array válido:', data);
              }
          };
      
          // Ejecutar la función inmediatamente al cargar el componente
          fetchData();
      
          // Configurar el intervalo para ejecutar la función cada minuto
          const interval = setInterval(fetchData, 30000);
      
          // Limpiar el intervalo al desmontar el componente
          return () => clearInterval(interval);
      }, []);
  // * fallas Activas
  useEffect(() => {
    const fetchData = async () => {
        const loggedIn = true
        const data = await fetchFallas(loggedIn);
        if (Array.isArray(data)) {
            setFallas(data);
            // console.log('data fallas',data)

        } else {
            // Manejar el caso en el que no se recibe un array
            //console.error('fetchAlertas no devolvió un array válido:', data);
        }
    };

    // Ejecutar la función inmediatamente al cargar el componente
    fetchData();

    // Configurar el intervalo para ejecutar la función cada minuto
    const interval = setInterval(fetchData, 30000);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, []);

  return (


    <Grid container justifyContent = "center" ref={mainContainerRef} style={{ width:"100vw", height:"90vh", overflow:'hidden',  }}>
      
        {/* { plc_status c === 100 || 200 ? null : statusGenerato === 60 
        ?  <AlertaGenerador parentRef={mainContainerRef} logo={Generador} title={'Alerta en Generador'}/> : '' }   */}
        {/* {  <AlertaGenerador parentRef={mainContainerRef} top={10} logo={Generador} title={'Alerta en Generador'} />  }   */}

        { center_data?.generadores?.plc_status === 100 ?  <AlertaGenerador parentRef={mainContainerRef} top={10} logo={Generador} title={'Alerta en Generador'} /> : "" }  
        { center_data?.generadores?.plc_status === -1 ?  <AlertaGenerador parentRef={mainContainerRef} top={10} logo={SinSeñal} title={'Sin Comunicación con Generador'} /> : "" }  
        { center_data?.generadores?.hf_status === -2 ?  <AlertaGenerador parentRef={mainContainerRef}  top={200} logo={SinSeñal} title={'Error Contacto Seco al Generador'} /> : "" }  

        <Grid container justifyContent={'center'} item xs={ center_data?.alertas_activas?.length === 0 && center_data?.alertas_fallas_activas?.length === 0 ? 12 : 10}>
        {center_data?.modulos?.length > 0 ? (
            center_data.modulos.slice( (currentPage - 1) * modulosPorPagina, currentPage * modulosPorPagina)
              .map((modulo, indiceModulo) => (
                <Grid container justifyContent="center" key={modulo.id} alignContent="center"
                  style={{
                    height: isSingleModule ? "77vh" : "39vh",
                    // maxWidth: "99.4vw",
                    // width: "98.4vw",
                    minWidth:'99%',
                    maxWidth:'99%',
                    flexGrow: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: indiceModulo === 1 ? '-9.2vh' : "0.2vh",
                    border: "solid 0.1vh #555",
                    // backgroundColor:'#111'
                  }}
                >
                  <Grid container justifyContent="center">
                    <Main_Bombas
                       cantidad_modulos={cantidad_modulos} translate={translate} modulo={modulo} indiceModulo={indiceModulo} modulosPorPagina={modulosPorPagina} controlType={controlType} modo={modo}
                    />
                  </Grid>
                </Grid>
              ))
          ) : (
            <Grid container justifyContent="center" alignContent="center">
              <Paper
                style={{
                  width: "99.4vw",
                  height: "75.5vh",
                  backgroundColor: "#171717",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1vh",
                }}
              >
                <Grid container justifyContent="center" alignContent="center">
                  <Grid container justifyContent="center" item xs={12}>
                    <div className="loading-spinner" style={{ marginTop: "30vh" }}></div>
                  </Grid>
                  
                  <Grid container justifyContent="center" item xs={12}>
                    <div style={{ marginTop: "2vh" }}>Cargando...</div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
        {/* Alertas */}
        { (fallas?.length > 0 || alertas?.length > 0) && 
          <Grid container justifyContent={'center'} item xs={1.9}>
            <Main_Alertas 
              alertas_activas={alertas} 
              fallas_activas={fallas} 
            />
          </Grid>
        }

        <Grid container justifyContent={'center'} alignContent={'center'} item xs={12} style={{ position:'fixed', bottom:'1vh', right:(fallas?.length > 0 || alertas?.length > 0) ?'8vw' : '0vw' }}>
          <Umbrales configuracionUmbrales={center_data.umbrales}/>
        </Grid>
    </Grid>
  )
}

export default Home;

