import React, { useState, useEffect } from 'react';
import BubbleRenderer from './assets/utils/BubbleRenderer'
// import ZoomBomba from './assets/utils/ZoomBomba'
import Status from './Status';
// import SistemaControlModal from '../Modals/SistemaControlModal';
import './assets/css/Bomba.css';

const Bomba = ({ bomba, idx_bomba, controlType, socket, modo, modulo }) => {
  // Estado para controlar la métrica mostrada (alterna entre oxígeno y saturación)
  const [selectedMetric, setSelectedMetric] = useState('oxigeno');
  const [o2Status, setO2Status] = useState('o2-normal');
  const [o2Falla, setO2Falla] = useState('o2-falla');

  const [isModalOpen, setIsModalOpen] = useState(false);
// console.log('bomba',bomba.SetPointOFF)
  // Estado para el tooltip
  // const [tooltip, setTooltip] = useState({ visible: false,text: '', x: 0, y: 0 });
    // Estado para manejar el tooltip
    const [tooltip, setTooltip] = useState(null);

  // Estado para controlar el zoom
  const [isZoomed, setIsZoomed] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // Al hacer clic se alterna la métrica
  const handleCardClick = () => {
    if (selectedMetric === 'oxigeno') {
      setSelectedMetric('saturacion');
    } else {
      setSelectedMetric('oxigeno');
    }
  };

  // Función para manejar el doble clic
  const handleDoubleClick = () => {
      setIsZoomed(true);
  };
  
  // Función para cerrar el zoom, si se requiere
  const handleCloseZoom = () => {
      setIsZoomed(false);
  };
  // Función auxiliar para validar que un valor sea un número válido
  const isValidNumber = (value) => typeof value === 'number' && !isNaN(value);

  // Selección de datos según la métrica
  let mainValue, mainUnit, chartData, valueMinY,valueMaxY, fixY, rightMarginY;
  if (selectedMetric === 'oxigeno') {
    // mainValue = bomba.O2 !== null ? (bomba.O2 < 0 ? `${bomba.O2_bckp}` : `${(+bomba.O2?.toFixed(1))}`) : bomba.O2_bckp; //bomba.O2 !== null ? `${(+bomba.O2?.toFixed(1))}` : "--";
    mainValue = isValidNumber(bomba.O2)
    ? (bomba.O2 < 0 ? `${bomba.O2_bckp}` : `${(+bomba.O2.toFixed(1))}`)
    : (isValidNumber(bomba.O2_bckp) ? bomba.O2_bckp : "--");
    mainUnit  = 'mg/L';
    // chartData = bomba.latest_measurements?.O2;
    chartData = Array.isArray(bomba.latest_measurements?.O2)
    ? bomba.latest_measurements.O2.filter((val) => isValidNumber(val))
    : [];
    valueMinY = 4;
    // valueMaxY = bomba?.SetPointOFF || 5;
    valueMaxY = isValidNumber(bomba?.SetPointOFF) ? bomba.SetPointOFF : 5;
    fixY      = 2;
    rightMarginY=10;
  } else if (selectedMetric === 'saturacion') {
    // mainValue = bomba.SO2 !== null ? (bomba.O2 < 0 ? `${bomba.SO2_bckp}` : `${(+bomba.SO2?.toFixed(1))}`) : bomba.SO2_bckp; //bomba.SO2 !== null ? `${(+bomba.SO2?.toFixed(1))}` : "--";
    mainValue = isValidNumber(bomba.SO2)
    ? (bomba.SO2 < 0 ? `${bomba.SO2_bckp}` : `${(+bomba.SO2.toFixed(1))}`)
    : (isValidNumber(bomba.SO2_bckp) ? bomba.SO2_bckp : "--");
    mainUnit  = '%';
    // chartData = bomba.latest_measurements?.SO2;
    chartData = Array.isArray(bomba.latest_measurements?.SO2)
    ? bomba.latest_measurements.SO2.filter((val) => isValidNumber(val))
    : [];
    valueMinY = 40;
    valueMaxY = 100;
    fixY      = 1;
    rightMarginY=11
  }

  // Pequeñas métricas para mostrar (diferentes a la seleccionada)
  const smallMetrics = [
    {
      name: 'oxigeno',
      // value: bomba.O2 !== null ? (bomba.O2 < 0 ? `${bomba.O2_bckp}` : `${(+bomba.O2.toFixed(1))}`) : "--",
      value: isValidNumber(bomba.O2)
      ? (bomba.O2 < 0 ? `${bomba.O2_bckp}` : `${(+bomba.O2.toFixed(1))}`)
      : "--",
      unit: 'mg/L',
      valueMinY:4,
    },
    {
      name: 'saturacion',
      // value: bomba.SO2 !== null ? (bomba.O2 < 0 ? `${bomba.SO2_bckp}` : `${(+bomba.SO2.toFixed(1))}`) : "--",
      value: isValidNumber(bomba.O2)
      ? (bomba.O2 < 0 ? `${bomba.O2_bckp}` : `${(+bomba.O2.toFixed(1))}`)
      : "--",
      unit: '%',
      valueMinY:40,
    },
  ].filter((m) => m.name !== selectedMetric);

  let status = 2;

  // useEffect(() => {
  //   const lowerLimit = bomba.SetPoint - 0.2;
  //   const upperLimit = bomba.SetPoint + 0.2;
  //   if (bomba.O2 < lowerLimit) {
  //     setO2Status('o2-low');
  //   } else if (bomba.O2 >= lowerLimit && bomba.O2 <= upperLimit) {
  //     setO2Status('o2-normal');
  //   } else {
  //     setO2Status('o2-high');
  //   }
  // }, [bomba.O2, bomba.SetPoint]);

  useEffect(() => {

    // const lowerLimit = bomba.SetPoint - 0.2;
    // const upperLimit = bomba.SetPoint + 0.2;
      // Validar que SetPoint sea un número válido antes de calcular límites
    const lowerLimit = isValidNumber(bomba.SetPoint) ? bomba.SetPoint - 0.2 : null;
    const upperLimit = isValidNumber(bomba.SetPoint) ? bomba.SetPoint + 0.2 : null;

    if (!isValidNumber(bomba.O2) || bomba.O2 < 0) {
      setO2Status('o2-falla');
    } else if (bomba.O2 < lowerLimit) {
      setO2Status('o2-low');
    } else if (bomba.O2 >= lowerLimit && bomba.O2 <= upperLimit) {
      setO2Status('o2-normal');
    } else {
      setO2Status('o2-high');
    }
  }, [bomba.O2, bomba.SetPoint]);


  // chart
// console.log('selectedMetric',selectedMetric)

  // mensaje tooltip 
  let fix;
  let vertical_x
  let horizontal_x1
  let horizontal_x2

  if (selectedMetric=== 'oxigeno') {
    fix           = 10
    vertical_x    = 0
    horizontal_x1 = 0
    horizontal_x2 = 5
  } else if (selectedMetric === 'saturacion') {
    fix           = 10
    vertical_x    = 0
    horizontal_x1 = 0
    horizontal_x2 = 5

  } else {
    // mensaje = "Sin Información Generador"; window.innerWidth*0.119
  }

  // Dimensiones del gráfico
const width = window.innerWidth*0.120;//300;
const height = window.innerHeight * 0.08;//80;
const padding = window.innerHeight*0.025;//25; // Se usará para el margen derecho (eje Y)

// Definir márgenes separados para la gráfica
const leftMargin = window.innerWidth*0.001// 1;      // Puedes ajustar este valor para mover el gráfico más a la izquierda
const rightMargin = padding + rightMarginY; // Se mantiene para el eje Y a la derecha

// Función para escalar las coordenadas en X usando el índice del arreglo
const getX = (index, total) =>
  leftMargin + (index / (total - 1)) * (width - leftMargin - rightMargin);

// Función para escalar las coordenadas en Y utilizando el valor mínimo y máximo
const getY = (value, minValue, maxValue) =>
  height - padding - ((value - minValue) / (maxValue - minValue)) * (height - 2 * padding);

// Función que genera la ruta SVG con una interpolación tipo Catmull-Rom convertida a cubic Bézier
function catmullRomPath(points) {
  // console.log('points', points)
  if (!points || points?.length < 2) return '';
  let d = `M ${points[0].x} ${points[0].y}`;
  for (let i = 0; i < points.length - 1; i++) {
    const p0 = i === 0 ? points[i] : points[i - 1];
    const p1 = points[i];
    const p2 = points[i + 1];
    const p3 = i + 2 < points.length ? points[i + 2] : p2;

    // Cálculo de puntos de control
    const cp1x = p1.x + (p2.x - p0.x) / 6;
    const cp1y = p1.y + (p2.y - p0.y) / 6;
    const cp2x = p2.x - (p3.x - p1.x) / 6;
    const cp2y = p2.y - (p3.y - p1.y) / 6;

    d += ` C ${cp1x} ${cp1y}, ${cp2x} ${cp2y}, ${p2.x} ${p2.y}`;
  }
  return d;
}
// Función para renderizar un gráfico dado un conjunto de datos
// En este caso, data es un arreglo de valores numéricos
// const renderChart = (data,mainUnit, color, title) => {
//   // console.log('data TS', bomba.latest_measurements.TS)
//   // console.log('data', data.length)
//   // Calcula el valor máximo y mínimo del dataset para escalar el eje Y
//   const max_Value = Math?.max(...data || []);
//   const maxValue = Math?.max( valueMaxY , max_Value);

//   const min_Value = Math?.min(...data || []);
//   const minValue = Math?.min(valueMinY, min_Value);

//   // Arma un arreglo de puntos con coordenadas X e Y escaladas
//   const points = data?.map((d, i) => ({
//     x: getX(i, data.length),
//     y: getY(d, minValue, maxValue),
//   }));

//   // Ticks del eje Y: primer tick = minValue, tick intermedio = promedio, último tick = maxValue
//   const ticks = [minValue, (minValue + maxValue) / 2, maxValue];
//   // const ticks = [minValue, (minValue + maxValue) / 2, maxValue];


//   // Maneja el movimiento del mouse en el área del gráfico
//   const handleMouseMove = (event) => {

//     if (!data || !Array.isArray(data)) return; // Si no hay datos, salimos

//     const svgRect = event.currentTarget.getBoundingClientRect();
//     const mouseX = event.clientX - svgRect.left;

//     // Buscar el índice del punto cuya coordenada X esté más cerca del mouse
//     let nearestIndex = 0;
//     let minDist = Infinity;
//     for (let i = 0; i < data?.length; i++) {
//       const x = getX(i, data?.length);
//       const dist = Math.abs(x - mouseX);
//       if (dist < minDist) {
//         minDist = dist;
//         nearestIndex = i;
//       }
//     }
//     const value = data?.[nearestIndex];
//     const x = getX(nearestIndex, data?.length);
//     const y = getY(value, minValue, maxValue);

//      // Extraer la hora de TS (se asume que bomba.latest_measurements.TS existe y tiene el mismo largo)
//     const fullTime = bomba.latest_measurements.TS?.[nearestIndex] || "";
//     const time = fullTime.split(" ")[1] || ""; // Ej: "09:02:00"

//     setTooltip({ x, y, value, time  });
//   };

//   const handleMouseLeave = () => {
//     setTooltip(null);
//   };
//   return (

//     <svg width={width} height={height}  onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
//       {/* Eje Y en el lado derecho */}
//       <line
//         x1={width - rightMargin - vertical_x}
//         y1={padding }
//         x2={width - rightMargin - vertical_x}
//         y2={height - padding}
//         stroke="#ccc"
//       />
//       {/* Ticks del eje Y */}
//       {ticks.map((tick, i) => {
//         const y = getY(tick, minValue, maxValue);
//         return (
//           <g key={i}>
//             <line
//               x1={width - rightMargin - horizontal_x1}
//               y1={y}
//               x2={width - rightMargin + horizontal_x2}
//               y2={y}
//               stroke="#ccc"
//             />
//             <text
//               x={width - rightMargin + fix}
//               y={y + 4}
//               textAnchor="start"
//               fontSize="0.5vw"
//               fill="white"
//             >
//               {tick?.toFixed(fixY)} 
//             </text>
//           </g>
//         );
//       })}
//       {/* Curva suave */}
//       <path
//         d={catmullRomPath(points)}
//         stroke={color}
//         fill="none"
//         strokeWidth="2"
//       />
//       {/* Tooltip: línea vertical, círculo y etiqueta con el valor */}
//       {tooltip && Array.isArray(chartData) && chartData.length > 0 &&  (
//         <g>
//           {/* Línea vertical que sigue el tooltip */}
//           <line
//             x1={tooltip.x}
//             y1={padding}
//             x2={tooltip.x}
//             y2={height - padding}
//             stroke="gray"
//             strokeDasharray="4"
//           />
//           {/* Círculo en el punto del tooltip */}
//           <circle cx={tooltip.x} cy={tooltip.y} r={4} fill="red" />
//           {/* Texto que muestra el valor */}
//           <text
//             x={tooltip.x}
//             y={tooltip.y - 10}
//             textAnchor="middle"
//             fontSize="0.5vw"
//             fill="white"
//           >
//             {/* {tooltip.time} - {tooltip?.value?.toFixed(2)} {mainUnit}  */}
//             <tspan x={tooltip.x} dx="1.1vw">{tooltip.value.toFixed(2)} {mainUnit}</tspan>
//             <tspan x={tooltip.x} dy="1.6vw" dx="1.1vw">{tooltip.time}</tspan>
//           </text>
//         </g>
//       )}
//     </svg>
//   );
// };
const renderChart = (data,mainUnit, color, title) => {
  // Validar que 'data' sea un array y filtrar valores nulos o no numéricos
  const validData = Array.isArray(data)
  ? data.filter((d) => d !== null && d !== undefined && !isNaN(d))
  : [];

   // Si no hay datos válidos, se muestra un mensaje
   if (validData.length === 0) {
     return (
       <svg width={width} height={height}>
         <text x={width / 2} y={height / 2} textAnchor="middle" fill="white">
           {/* No hay datos disponibles */}
         </text>
       </svg>
     );
   }
   // Calcular valores máximos y mínimos a partir de los datos válidos
   const maxDataValue = Math.max(...validData);
   const minDataValue = Math.min(...validData);
   const maxValue = Math.max(valueMaxY, maxDataValue);
   const minValue = Math.min(valueMinY, minDataValue);
 
   // Generar puntos para el gráfico
   const points = validData.map((d, i) => ({
     x: getX(i, validData.length),
     y: getY(d, minValue, maxValue),
   }));
 
   // Definir ticks para el eje Y
   const ticks = [minValue, (minValue + maxValue) / 2, maxValue];
 
   // Manejo del movimiento del mouse sobre el gráfico
   const handleMouseMove = (event) => {
     if (!validData || validData.length === 0) return;
 
     const svgRect = event.currentTarget.getBoundingClientRect();
     const mouseX = event.clientX - svgRect.left;
 
     let nearestIndex = 0;
     let minDist = Infinity;
     for (let i = 0; i < validData.length; i++) {
       const x = getX(i, validData.length);
       const dist = Math.abs(x - mouseX);
       if (dist < minDist) {
         minDist = dist;
         nearestIndex = i;
       }
     }
     const value = validData[nearestIndex];
     const x = getX(nearestIndex, validData.length);
     const y = getY(value, minValue, maxValue);
 
     // Validar que 'TS' exista y sea un array antes de acceder
     const fullTime =
       bomba.latest_measurements?.TS && Array.isArray(bomba.latest_measurements.TS)
         ? bomba.latest_measurements.TS[nearestIndex]
         : "";
     const time = fullTime.split(" ")[1] || ""; // Ejemplo: "09:02:00"
 
     setTooltip({ x, y, value, time });
   };
 
   const handleMouseLeave = () => {
     setTooltip(null);
   };
 return (

    <svg width={width} height={height}  onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
      {/* Eje Y en el lado derecho */}
      <line
        x1={width - rightMargin - vertical_x}
        y1={padding }
        x2={width - rightMargin - vertical_x}
        y2={height - padding}
        stroke="#ccc"
      />
      {/* Ticks del eje Y */}
      {ticks.map((tick, i) => {
        const y = getY(tick, minValue, maxValue);
        return (
          <g key={i}>
            <line
              x1={width - rightMargin - horizontal_x1}
              y1={y}
              x2={width - rightMargin + horizontal_x2}
              y2={y}
              stroke="#ccc"
            />
            <text
              x={width - rightMargin + fix}
              y={y + 4}
              textAnchor="start"
              fontSize="0.5vw"
              fill="white"
            >
              {tick?.toFixed(fixY)} 
            </text>
          </g>
        );
      })}
      {/* Curva suave */}
      <path
        d={catmullRomPath(points)}
        stroke={color}
        fill="none"
        strokeWidth="2"
      />
      {/* Tooltip: línea vertical, círculo y etiqueta con el valor */}
      {tooltip && Array.isArray(chartData) && chartData.length > 0 &&  (
        <g>
          {/* Línea vertical que sigue el tooltip */}
          <line
            x1={tooltip.x}
            y1={padding}
            x2={tooltip.x}
            y2={height - padding}
            stroke="gray"
            strokeDasharray="4"
          />
          {/* Círculo en el punto del tooltip */}
          <circle cx={tooltip.x} cy={tooltip.y} r={4} fill="red" />
          {/* Texto que muestra el valor */}
          <text
            x={tooltip.x}
            y={tooltip.y - 10}
            textAnchor="middle"
            fontSize="0.5vw"
            fill="white"
          >
            {/* {tooltip.time} - {tooltip?.value?.toFixed(2)} {mainUnit}  */}
            {/* <tspan x={tooltip.x} dx="1.1vw">{tooltip.value.toFixed(2)} {mainUnit}</tspan> */}
            <tspan x={tooltip.x} dx="1.1vw">
              {typeof tooltip.value === "number" && !isNaN(tooltip.value)  ? tooltip.value.toFixed(2) : "N/A"}{" "}   {mainUnit}   </tspan>
            <tspan x={tooltip.x} dy="1.6vw" dx="1.1vw">  {tooltip.time || ""} </tspan>
            {/* <tspan x={tooltip.x} dy="1.6vw" dx="1.1vw">{tooltip.time}</tspan> */}
          </text>
        </g>
      )}
    </svg>
 );
};

  // console.log('print(f"Contenido de DB_buffer antes de guardar:")',idx_bomba)
  return (
    <div className="tooltip-container" style={{marginLeft: idx_bomba === 2 || 3 ? '-2vw':'0vw'}}>
        {/* Tooltip sensor */}
        <span className="tooltip"> {/*Última Medición de sensor */}{ bomba.last_measure_sensor}</span>
      <div
        onClick={handleCardClick}
        // onDoubleClick={handleDoubleClick}
        // className={
        //   bomba.aviot_status === false
        //     ? 'BombaNoData'
        //     : `health-card ${bomba.InSel === '1' ? '' : 'no-animation'} ${
        //         status === 0
        //           ? 'borde-normal'
        //           : bomba.O2 < bomba.SetPoint && bomba.Device_Modbus_Status === false || bomba.InStop === "1" || bomba.Status === 13
        //           ? 'borde-rojo-parpadeante'
        //           : bomba.O2 < 0 || bomba.O2 === null
        //           ? 'borde-amarillo-parpadeante'
        //           : ''
        //       }`
        // }
        className={
          bomba.aviot_status === false
            ? 'BombaNoData'
            : `health-card ${bomba.InSel === '1' ? '' : 'no-animation'} ${
                status === 0
                  ? 'borde-normal'
                  : bomba.O2 < bomba.SetPoint && bomba.Device_Modbus_Status === false || bomba.InStop === "1" || bomba.Status === 13
                  ? 'borde-rojo-parpadeante'
                  // : bomba.O2 < 0 || bomba.O2 === null
                  // ? 'borde-amarillo-parpadeante'
                  : ''
              }`
        }
        
        style={{ position: 'relative' }} // Para posicionar el tooltip
      >
        {/* {isZoomed ? (
          // Solo se renderiza el componente ZoomBomba al hacer zoom
          <ZoomBomba onClose={handleCloseZoom} />
          ) : ( */}
            <>
              {bomba.aviot_status === false ? (
                <p className="bomba-no-data">BOMBA SIN DATOS</p>
              ) : (
                <>
                  {/* Encabezado */}
                  <div className="header">
                    <div className="title-ALIAS">{bomba.alias}</div>
                    <div className="hint">
                      <button className="animated-btn" onClick={handleOpenModal}>
                        <span className="animated-btn__text">
                          {/* {bomba.Control === true ? 'Auto' : 'Manual'} */}
                          {bomba.aviot_status === null ? (bomba.Control === true ? 'Auto' : 'Manual') : (bomba.Control === '1' ? 'Auto' : 'Manual')}
                        </span>
                      </button>
                    </div>
                  </div>
                  {/* CONTROL */}
                 
                  {!modo &&
                    <Status bomba={bomba} controlType={controlType}/>
                  }
                  {/* Gráfico interactivo con tooltip */}
                  <div className="chart-container"  onDoubleClick={handleDoubleClick}>
                  { (chartData !== undefined && chartData !== null && !Number.isNaN(chartData) &&
                     bomba.O2 > 0 || bomba.O2 !== null) ?
                    renderChart(chartData,mainUnit, '#ff5370')
                    : null
                  }
                  </div>

                  {/* Valores debajo del gráfico */}
                  {bomba.O2 > 0 ? (
                    <div className="values-container">
                      <div className={`main-value ${o2Status}`}>
                        {mainValue}
                        <span className="unit">{mainUnit}</span>
                      </div>
                      <div className="small-values">
                        <div className={`small-value ${o2Status}`}>
                          {bomba.TEMP !== null ? `${(+bomba.TEMP?.toFixed(1))}` : "--"}
                          <span className="small-value-unit">°C</span>
                        </div>
                        {smallMetrics.map((m) => (
                          <div key={m.name} className={`small-value  ${o2Status}`}>
                            {m.value} <span className="small-value-unit">{m.unit}</span>
                          </div>
                        ))}
                      </div>
                       {/* MODO */}
                      <div className={`manual-text ${!modo && 'detallada'}`}>
                        {/* {bomba.InSel === true ? '' : 'Manual'} */}
                        {bomba.aviot_status === null ? (bomba.InSel === true ? '' : 'Manual') : (bomba.InSel === '1' ? '' : 'Manual')}
                      </div>
                    </div>
                  ) : (
                    // <p className="falla-sensor">Falla de Sensor {bomba.O2_bckp}</p>
                    <>
                      <div className="alerts-container-falla">
                        <div role="alert" className={`alert-status-falla ${'alert-warning'}`}>
                          <p className="alert-text"> Promedio de sensores cercanos </p>
                        </div>
                      </div>
                      <div className="values-container" style={{marginTop:'4.5vh'}}>
                        <div className={`main-value ${o2Falla}`}>
                          {mainValue}
                          <span className="unit">{mainUnit}</span>
                        </div>
                        <div className="small-values">
                          <div className={`small-value ${o2Falla}`}>
                            Falla de Sensor
                          </div>
                        </div>
                        {/* MODO */}
                        <div className={`manual-text ${!modo && 'detallada'}`}>
                          {/* {bomba.InSel === true ? '' : 'Manual'} */}
                          {bomba.aviot_status === null ? (bomba.InSel === true ? '' : 'Manual') : (bomba.InSel === '1' ? '' : 'Manual')}
                        </div>
                      </div>
                    </>
                  )}
                  
                 
                  {/* VOLTAJE CORRIENTE */}
                  {!modo &&
                    <div className="voltaje-corriente"> 
                      <div className="manual-text-detallada">
                        {bomba.aviot_status === null ? (bomba.InSel === true ? 'Auto' : 'Manual') : (bomba.InSel === '1' ? 'Auto' : 'Manual')}
                        {/* {bomba.aviot_status === null ? (bomba.InSel === true ? '' : 'Manual') : (bomba.InSel === '1' ? '' : 'Manual')} */}
                      </div>
                      <div className="datos-electricos">
                        {bomba.Vo !== null ? `${(+bomba.Vo?.toFixed(1))} V` : "--"} | {bomba.Io !== null ? `${(+bomba.Io?.toFixed(1))} A` : "--"}
                      </div>
                    </div>
                  }

                  {/* Contenedor de las burbujas */}
                  {bomba.O2 > 0 && bomba.RLY ?
                    <BubbleRenderer isActive={bomba.RLY} />
                  :
                  <></>
                  } 
                </>
              )}
              <style>
                {`
                  @keyframes bubble-animation {
                    0% {
                      transform: translateY(0) scale(1);
                      opacity: 0.8;
                    }
                    50% {
                      transform: translateY(-100px) scale(1.2);
                      opacity: 0.5;
                    }
                    100% {
                      transform: translateY(-200px) scale(0.8);
                      opacity: 0;
                    }
                  }
                `}
              </style>
            </>
          {/* )} */}
        {/* {isModalOpen && (
            <SistemaControlModal  modulo={modulo} isModalOpen={isModalOpen} onClose={handleCloseModal} bomba={bomba}  idx_bomba={idx_bomba} nombre={bomba.nombre} Aviot={bomba.ip_aviot} socket={socket} controlType={controlType} />
        )}  */}
        {/* Modal: overlay que cubre toda la app y muestra ZoomBomba */}
        {/* {isZoomed && (
          <div className="modal-overlay" onClick={handleCloseZoom}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <ZoomBomba bomba={bomba} onClose={handleCloseZoom} />
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Bomba;