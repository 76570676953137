import React, {useState, useEffect, useContext} from 'react';
import { Grid, IconButton, Menu, MenuItem} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventNoteIcon from '@mui/icons-material/EventNote';

import './utils/css/Historial_Control.css'

const Historial_Control = ({center_data, datos,historialControl}) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const[historialContol,setHistorialControl] = useState([]);
    const [lastHistorial, setLastHistorial] = useState(null);
    const [selectedFalla, setSelectedFalla] = useState(false);
    const [showAllData, setShowAllData] = useState(true);

 

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(false);
    };
    const handleShowAllData = () => {
        setSelectedFalla(false);
        setShowAllData(true);
    };

    useEffect(() => {
        if (lastHistorial !== datos?.historial_control) {
            setLastHistorial(datos?.historial_control);
    
            const datosHistorial = datos?.historial_control;
            setHistorialControl(prevInfo => [...prevInfo, datosHistorial]);
        }
    }, [datos?.historial_control, lastHistorial]);
// console.log("center_data",center_data)
  return (
    <Grid item xs={6} style={{ zIndex: 1 , marginLeft:'5vw'}}>
        {/* <Button
            onClick={handleClick}
            variant='outlined'
            color='inherit'
            style={{width:"20vw", fontSize:"1vw", height:"5vh"}}
        >
            Historial de Control
            <ExpandMoreIcon />
        </Button> */}

        <IconButton className="note"  onClick={handleClick}>
            <EventNoteIcon className="note-icon"/>
        </IconButton>

        <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
        // style={{backgroundColor:'rgb(29, 25, 38)'}}
            onClose={handleClose} // Eliminar onClose para que el menú no se cierre al hacer clic en un elemento
        >
            <Grid container justifyContent="center">
                {/* <MenuItem  onClick={handleShowAllData}>Mostrar Todos</MenuItem> Nuevo botón */}
            </Grid>
            {/* <MenuItem style={{ maxHeight: '70vh', minHeight:"70vh" }} >
                <ul style={{ maxHeight: '70vh', overflowY: 'auto', marginLeft:"-2vw" }}>
                    {datos && datos.historial_control ? (
                        <ul style={{ maxHeight: '70vh', overflowY: 'auto', marginLeft:"-2vw" }}>
                            {datos.historial_control.map((historial,idx) => (
                                <li key={idx}>
                                    {historial.msg} el {historial.date}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No hay datos de historial para mostrar.</p>
                    )}
                </ul>
            </MenuItem> */}
            <Grid container justifyContent={'center'} style={{ backgroundColor:'#1a1a1a', maxHeight: '72vh',minHeight: '72vh',overflowY: 'hidden'}}>
                <ul style={{ maxHeight: '69vh',minHeight: '69vh',width:'55vw', overflowY: 'auto', marginLeft: "-0vw",marginTop:"1vw"  }}>
                    {center_data && center_data.historial_control ? (
                        center_data.historial_control.slice().reverse().map((historial, idx) => (
                            <li key={idx} style={{fontSize:"0.85vw", color:historial.color}}>
                                {historial.msg}
                            </li>
                        ))
                    ) : (
                        <p>No hay datos de historial para mostrar.</p>
                    )}
                </ul>
            </Grid>
        </Menu>
    </Grid>
  )
}

export default Historial_Control