import React,{useState, useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import MuiAlert from '@mui/material/Alert';

import DateSelectorNanoox from "./utils/DateSelectorNanoox";
import {downloadReport} from "../../../utils/ApiServer"



const ModalStyle = {
    position: 'absolute',
    marginTop: '28vh',
    marginLeft: '29vw',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#282828',
    borderRadius: '1vw',
    // border: '1px solid',
    width:'47vw',
    height:'49vh'
};









function ModalCalendario({  controlType, openModal, setOpenModal, idioma, translate ,notificationOpen, setNotification_open, setNotification_msg, setNotification_status}) {

     // -- Descargar Reportes -- //
const [cargando, setCargando] = useState(false);

// console.log('controlType',controlType)
 const makeReport = async (startDate, endDate, reportType) => {
  setCargando(true)
    // Obtener credenciales del cliente
    let region, area, centro;
    try {
        region = localStorage.getItem('REGION');
        area = localStorage.getItem('AREA');
        centro = localStorage.getItem('CENTRO');
    } catch {
        region = null;
        area = null;
        centro = null;
    }

    // Determinar la ruta de la API según el tipo de reporte
    let endpoint;
    if (reportType === 'reporteSensores') {
        endpoint = `/NANOOX/generate_nanoox_report/${region}/${area}/${centro}/${controlType}`;
    } else if (reportType === 'reporteHistorialControl') {
        endpoint = `/NANOOX/generate_historical_report/${region}/${area}/${centro}`;
    }

    console.log('Solicitando reporte de: ', startDate, endDate, 'Tipo de reporte: ', reportType);
    const result = await downloadReport(endpoint, { startDate, endDate }, reportType,  setNotification_open, setNotification_msg, setNotification_status);

    if (result.error) {
        setNotification_msg(result.message);
        setNotification_status('error');
        setNotification_open(true);
      } else {
        setNotification_msg('Reporte Generado');
        setNotification_status('success');
        setNotification_open(true);
        setCargando(false)

      }

};

useEffect(() => {
    if (notificationOpen) {
      const timer = setTimeout(() => {
        setNotification_open(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notificationOpen]);


  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => prev.length < 3 ? prev + '.' : '');
    }, 500); // cada 500ms se añade un punto

    return () => clearInterval(interval); // limpiar el intervalo al desmontar
  }, []);
    

    return (
        <>
          {cargando && 
            <Alert variant="filled" severity="info" style={{position:'absolute', marginTop:'9vh',  width: "20vw",height:'5.3vh', zIndex:55, marginLeft: "79vw", fontSize: '1.7vh', alignContent: 'center',
            justifyContent: "center", cursor: 'default !important', userSelect: 'none'}}>
              Generando Reporte {dots}
            </Alert>
          }
            {openModal && // Asegura que el componente se desmonte por completo
                (/* Modal Fechas */
                    < Modal
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                    >
                        <Box style={ModalStyle} >
                            <DateSelectorNanoox  makeReport={makeReport} closeModal={() => setOpenModal(false)} idioma={idioma} translate={translate} setNotification_open={setNotification_open} setNotification_msg={setNotification_msg} setNotification_status={setNotification_status} />
                        </Box>
                    </Modal >
                )
            }
        </>
    );
}

export default ModalCalendario;
