import React,{useState, useEffect} from 'react';
import {styled, useTheme } from '@mui/material/styles';
import { Box, Grid} from '@mui/material';

import Home from '../../pages/Home';
import { MyAppBar } from './MyAppBar';
import { get_data, tipo_control } from '../../utils/ApiServer';

export default function Main({
  handleDrawer,
  capitalize, 
  setIsAuthenticated, 
  SWVersion, 
  translate ,
  handleClick ,
  openMenuIdioma ,
  handleCloseIdioma ,
  handleLanguageChange, 
  idioma,
  notificationOpen, 
  setNotification_open, 
  setNotification_msg, 
  setNotification_status,
  selectedRegion,setSelectedRegion, 
  selectedArea, setSelectedArea, 
  consolidado_estados,
  setOtherWindowsIndex,
  otherWindowsIndex}) {

  const theme = useTheme();

  const [controlType, setControlType] = useState(''); // Estado para controlar la vista para //* -- VARIADOR DE FRECUENCIA -- || -- PARTIDOR SUAVE --
  const [open, setOpen] = useState(true);
  const [mostrarAlertas, setMostrarAlertas] =useState(false)
  const service = 'nanoox'
  const [center_data,setCenter_data]= useState({})
  // const [regionSeleccionada, setRegionSeleccionada] = useState(localStorage.getItem('REGION'));
  // const [areaSeleccionada, setAreaSeleccionada] = useState(localStorage.getItem('AREA'));
  const [centroSeleccionado, setCentroSeleccionado] = useState(localStorage.getItem('CENTRO'));
  const [nameCentro, setNameCentro] = useState(localStorage.getItem('CENTRO'));
  const [modo , setModo] = useState(true) // modo de vista: true simple ; false complejo


// console.log('controlType',controlType)
  // *  -----------------------------  Función: handleRegionClick ------------------------------------------------
  //? Descripción: 
  //? Función utilizada para poder seleccionar Regiones
  //? Parámetros:
  // - region : objeto con atributo 'clientId' que corresponde al indice del cliente 'name' que corresponde a la region seleccionada
  //            y un atributo 'area' que es un array de objetos de las areas de la region seleccionada, objeto que corresponde al que se desbribe en los parametros de handleAreaClick 
  const handleRegionClick = (region) => {
    // console.log('region NANOOX',region)
    setSelectedRegion(region);
  };
  const handleCentroClick = async (centerName) => {
    console.log('centerName ',centerName)
    setCentroSeleccionado(centerName);
    let REGION = localStorage.getItem('REGION')
    let AREA   = localStorage.getItem('AREA')
    let CENTRO = localStorage.getItem('CENTRO')

    try {
      // Llamar a la función get_data y guardar los datos en una variable
      // const centerData = await get_data(selectedRegion.name, selectedArea, centro, service);
      const centerData = await get_data(REGION, AREA, CENTRO, centerName);
      
      // Manejar la respuesta, por ejemplo, imprimir en la consola
      //console.log('Datos obtenidos:', centerData);

      // Puedes guardar los datos en el estado o en una variable local según tus necesidades
      // Por ejemplo, aquí los estoy guardando en el estado
      setCenter_data(centerData);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  useEffect(() => {
    // Esta función se ejecutará al montar el componente
    const fetchData = async () => {
      try {
        let REGION = localStorage.getItem('REGION')
        let AREA   = localStorage.getItem('AREA')
        let CENTRO = localStorage.getItem('CENTRO')
        // Llamar a la función get_data y guardar los datos en una variable
        // const centerData = await get_data(selectedRegion.name, selectedArea, centroSeleccionado);
        const centerData = await get_data(REGION, AREA, CENTRO);

        // Manejar la respuesta, por ejemplo, imprimir en la consola
        // //console.log('Datos obtenidos:', centerData);

        // Puedes guardar los datos en el estado o en una variable local según tus necesidades
        // Por ejemplo, aquí los estoy guardando en el estado
        setCenter_data(centerData);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    // Llamar a fetchData inicialmente
    fetchData();

    // Establecer un intervalo para llamar a fetchData cada 1 minuto (60,000 milisegundos)
    const intervalId = setInterval(fetchData, 60000);

    // Limpiar el intervalo al desmontar el componente para evitar fugas de memoria
    return () => clearInterval(intervalId);
  }, []); // Dependencias que deben activar la ejecución del efecto

    // //*--------------------- tipo de control ------------------
    useEffect(() => {
      const fetchData = async () => {
          const result = await tipo_control();
          console.log('response: tipo_control1111', result?.datos)
          setControlType(result?.datos)
  
      };
  
      // Fetch data initially
      fetchData();
  
      // // Set interval to fetch data every 60 seconds
      // const interval = setInterval(fetchData, 60000);
  
      // // Clean up interval on component unmount
      // return () => clearInterval(interval);
  }, []);
  

//console.log('center_data',center_data.hora)
  const handleDrawerToggle = () => {
    setOpen(!open); // Cambia el estado opuesto al estado actual
  };

// console.log('center_data', center_data)

  const MyAppBarProps = {
    SWVersion: SWVersion,
    handleDrawer:handleDrawer,
    handleDrawerToggle: handleDrawerToggle,
    setIsAuthenticated: setIsAuthenticated,
    handleCentroClick: handleCentroClick,
    nameCentro: nameCentro,
    translate: translate,
    handleClick: handleClick,
    openMenuIdioma:openMenuIdioma,
    handleCloseIdioma:handleCloseIdioma,
    handleLanguageChange:handleLanguageChange,
    idioma:idioma,
    ultimaHora:center_data.hora,
    center_data:center_data,
    notificationOpen:notificationOpen,
    setNotification_open:setNotification_open,
    setNotification_msg:setNotification_msg,
    setNotification_status:setNotification_status,
    selectedRegion:selectedRegion,
    selectedArea:selectedArea,
    setSelectedRegion:setSelectedRegion,
    setSelectedArea:setSelectedArea,
    consolidado_estados:consolidado_estados,
    open: open,
    theme: theme,
    handleCentroClick: handleCentroClick,
    centroSeleccionado: centroSeleccionado,
    setNameCentro: setNameCentro,
    translate: translate,
    capitalize:capitalize,
    setMostrarAlertas:setMostrarAlertas,
    mostrarAlertas:mostrarAlertas,
    modo: modo,
    setModo : setModo,
    handleRegionClick:handleRegionClick,
    controlType:controlType,
    setOtherWindowsIndex,
    otherWindowsIndex

  } 

 

  const HomeProps = {
    center_data: center_data,
    setCenter_data: setCenter_data,
    centroSeleccionado: centroSeleccionado,
    translate: translate,
    mostrarAlertas:mostrarAlertas,
    controlType:controlType,
    modo: modo,
    // handleClick: handleClick,
    // openMenuIdioma:openMenuIdioma,
    // handleCloseIdioma:handleCloseIdioma,
    // handleLanguageChange:handleLanguageChange,
  }

  console.log('controlType',controlType)

  return (
    <Grid container style={{ display: 'flex', width:'99.6vw', height:'99.8vh', overflowY:'hidden', overflowX:'hidden'}}>
        <MyAppBar {...MyAppBarProps}/>
      <Box style={{ marginTop:"2vh", overflow:'hidden', width:'99.6vw', height:'90vh'}}>
         <Home {...HomeProps}/>
      </Box>
    </Grid>
  );
}
