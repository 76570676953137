// import React, {useState, useRef, useEffect ,useCallback} from "react";

// const BubbleRenderer = React.memo(({ isActive }) => {
//   const [bubbles, setBubbles] = useState([]);
//   const bubbleId = useRef(4);
//   const activeBubbles = useRef(4);
//   const MAX_BUBBLES = 5;

//     // Función para generar una nueva burbuja
//     const addBubble = () => {
//         if (activeBubbles.current >= MAX_BUBBLES) {
//             return; // No añadir más burbujas si se alcanza el máximo
//         }

//         const id = bubbleId.current++;
//         const left = Math.random() * 90; // Posición horizontal aleatoria (0% a 100%)
//         const duration = 5 + Math.random() * 3; // Duración aleatoria entre 4s y 7s

//         const newBubble = { id, left, duration };
//         setBubbles(prev => [...prev, newBubble]);
//         activeBubbles.current += 1;
//         // console.log(`Añadida burbuja ${id} en ${left.toFixed(2)}% con duración ${duration.toFixed(2)}s`);

//         // Programar la eliminación de la burbuja después de su duración
//         setTimeout(() => {
//             setBubbles(currentBubbles => currentBubbles.filter(bubble => bubble.id !== id));
//             // console.log(`Eliminada burbuja ${id}`);
//             // Generar la siguiente burbuja después de un retraso aleatorio
//             const delayBeforeNext = 500 + Math.random() * 500; // Entre 0.5s y 2s
//             setTimeout(() => {
//                 addBubble();
//             }, delayBeforeNext);
//             // activeBubbles.current -= 1;
            
//         }, duration * 1000);
//     };


//     useEffect(() => {
//         if (isActive) {
//             addBubble();
//         }

//         return () => {
//             setBubbles([]);
//         };
//     }, [isActive]);



//   return (
//     <div style={{
//       position: 'absolute',
//       height: '14vh',
//       width: '5vw',
//       display: 'flex',
//       flexDirection: 'column',
//       alignItems: 'center',
//       overflow: 'hidden',
//       marginTop: '-13vh',
//       marginLeft: '6vw',
//       // border:'solid'
//     }}>
//       {bubbles.map((bubble) => (
//         <div key={bubble.id} style={bubbleStyle(bubble.left, bubble.duration)}></div>
//       ))}
//     </div>
//   );
// });
// export default BubbleRenderer;


// const bubbleStyle = (left, duration) => ({
//   position: 'absolute',
//   bottom: '0',
//   width: '0.5vw', // Tamaño visible
//   height: '0.5vw',
//   backgroundColor: 'rgba(173, 216, 230, 0.8)', // LightBlue
//   borderRadius: '50%',
//   animation: `bubble-animation ${duration}s forwards`,
//   left: left,
//   opacity: '0.8',
//   zIndex: 10,
// });

import React, { useState, useRef, useEffect, useCallback } from "react";

const BubbleRenderer = React.memo(({ isActive = true }) => {
  const [bubbles, setBubbles] = useState([]);
  const maxBubbles = 10; // Número fijo de burbujas simultáneas
  const bubbleId = useRef(1);
  const activeBubbles = useRef(0);

  const addBubble = useCallback(() => {
    // Verificar si se alcanzó el máximo permitido
    if (activeBubbles.current >= maxBubbles) return;

    const id = bubbleId.current++;
    const left = Math.random() * 90; // Posición horizontal aleatoria
    const duration = 4 + Math.random() * 3; // Duración aleatoria entre 5s y 8s

    setBubbles((prev) => [...prev, { id, left, duration }]);
    activeBubbles.current += 1;

    // Programar la eliminación de la burbuja después de su duración
    setTimeout(() => {
      setBubbles((currentBubbles) => currentBubbles.filter((bubble) => bubble.id !== id));
      activeBubbles.current -= 1;

      // Generar la siguiente burbuja después de un retraso aleatorio, si sigue activo
      const delayBeforeNext = 500 + Math.random() * 500; // Entre 0.5s y 1s
      if (isActive) {
        setTimeout(addBubble, delayBeforeNext);
      }
    }, duration * 1000);
  }, [isActive, maxBubbles]);

  useEffect(() => {
    if (isActive) {
      // Si está activo y aún no se han llenado las burbujas, generamos hasta maxBubbles
      if (activeBubbles.current === 0) {
        for (let i = 0; i < maxBubbles; i++) {
          addBubble();
        }
      }
    } else {
      // Si ya no está activo, limpiar el estado y contadores
      setBubbles([]);
      activeBubbles.current = 0;
      bubbleId.current = 1;
    }
  }, [isActive, addBubble, maxBubbles]);

  return (
    <div style={containerStyle}>
      {bubbles.map((bubble) => (
        <div key={bubble.id} style={bubbleStyle(bubble.left, bubble.duration)}></div>
      ))}

      <style>
        {`
          @keyframes bubble-animation {
            0% {
              transform: translateY(0) scale(1);
              opacity: 0.8;
            }
            50% {
              transform: translateY(-100vh) scale(1.2);
              opacity: 0.5;
            }
            100% {
              transform: translateY(-200vh) scale(0.8);
              opacity: 0;
            }
          }
        `}
      </style>
    </div>
  );
});

export default BubbleRenderer;

const containerStyle = {
  position: 'relative',
  height: '14vh',
  width: '5vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden',
  marginTop: '-13vh',
  marginLeft: '6vw',
};

const bubbleStyle = (left, duration) => ({
  position: 'absolute',
  bottom: '0',
  width: '0.5vw',
  height: '0.5vw',
  backgroundColor: 'rgba(173, 216, 230, 0.8)',
  borderRadius: '50%',
  animation: `bubble-animation ${duration}s forwards`,
  left: `${left}%`,
  opacity: '0.8',
  zIndex: 10,
});
