
import React from 'react'
import { Grid } from '@mui/material'
import Pagination from '@mui/material/Pagination';

const PaginationButtons = ({ currentPage, setCurrentPage, totalPages, handleModulePageChange, modulo, canPorPagina }) => {


  // Manejar el cambio de página
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const pageCount = modulo && modulo.cans.length > 0
  ? Math.ceil(modulo.cans.length / canPorPagina)
  : 0;
  return (
    <Grid container justifyContent={'center'} >
      <Grid container justifyContent={'center'} alignContent={'center'} alignItems="center" item xs={ modulo.cans.length > 3 ? 10 :12}>
        <h2 style={{fontSize:"3vh"}}>Módulo {modulo.id}</h2>
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center" item xs={2} >
      { modulo.cans.length > 3 &&
          <Pagination
              variant="outlined" shape="rounded" 
              sx={{ 
                // flexGrow: 1,
                // textAlign:'center',
                  marginTop: "3vh", // Desplazamiento a la izquierda en 5% del ancho de la ventana
                '& .MuiPaginationItem-root': {
                    fontSize: '1vw', // Ajusta el tamaño de los números de la paginación en vw
                    border:'solid 0.1vh #555555',
                    height:'3vh',
                    minWidth:'2vw',
                    maxWidth:'2vw',
                    borderRadius:'0.2vw',
                    textAlign: 'center',
                },
                '& .MuiPaginationItem-previousNext': {
                      fontSize: '30vw', // Tamaño de los íconos de avanzar y retroceder en vw
                      '& svg': {
                        fontSize: '1vw', // Ajusta el tamaño de los íconos en los botones de avance/retroceso
                      }
                  },
                  '& .MuiPaginationItem-previousNext.Mui-disabled': {
                    fontSize: '2vw', // Tamaño de los íconos deshabilitados en vw (puedes cambiarlo a un tamaño diferente)
                    color: 'gray', // Cambiar color cuando está deshabilitado
                    border:'solid 0.1vh',
                    height:'3vh',
                    minWidth:'1.5vw',
                    maxWidth:'1.5vw',
                    borderRadius:'0.2vw',
                    '& svg': {
                      fontSize: '1vw', // Ajusta el tamaño de los íconos en los botones de avance/retroceso
                    }
                },
              }}
              // count={datos && datos.modulos.length > 0 ? Math.ceil(datos.length / modulosPorPagina) : 0}
              count={pageCount} // El número total de páginas
              page={currentPage} // La página actual
              onChange={handlePageChange} // La función que se llama al cambiar de página
              color="standard" // El color del paginador
          />
        }
      </Grid>
      
    </Grid>
  );
};

export default PaginationButtons;
