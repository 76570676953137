
import React from 'react';
import { Grid, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';


const AlertasActivas = ({alertaSeleccionada, onClose}) => {
    const getSensorName = (sensor) => {
    switch(sensor) {
    case 'O2': return   'Oxígeno';
    case 'DO2': return  'Saturación';
    case 'Vo': return   'Voltaje';
    case 'Io': return   'Corriente';
    case 'Press': return 'Presión';
    default: return sensor;
    }
    };

    const getSensorUnit = (sensor) => {
    switch(sensor) {
    case 'O2': return '[ mg/L ]';
    case 'DO2': return '[ V ]';
    case 'Vo': return '[ A ]';
    case 'Io': return '[ % ]';
    case 'Press': return '[ bar ]';
    default: return sensor;
    }
    };

    const getSensorTipo = (rango) => {
    switch(rango) {
    case 0: return 'Dentro del Umbral';
    case 1: return 'Sobre de Umbral';
    case -1: return 'Bajo del Umbral';
    default: return rango;
    }
    };

    const getBackgroundColor = (rango) => {
    switch (rango) {
    case -1:
    case 1:
    return '#ac2929';
    case 0:
    return '#296f4d';
    default:
    return 'transparent';
    }
    };

    const [año, mes, dia, hora ] = alertaSeleccionada?.fecha?.split('-') ? alertaSeleccionada?.fecha?.split('-') : '';
    const [modulo,can, jaula, bomba, val,sensor] = alertaSeleccionada?.variable?.split('-') ? alertaSeleccionada?.variable?.split('-') : '';
 
    // console.log('1-HF1-101_102_103_104-VA1_VA2_VA3_VA4-3-Press',alertaSeleccionada)
    if (!alertaSeleccionada) {
        return (
        <Grid container justifyContent={'center'} alignContent={'center'} item xs={11} 
            style={{backgroundColor:'#0d0d0d', borderRadius:'0.5vw', marginBottom:'1vh', border:'solid 0.1vh #7f0000', fontSize:'1vw', color:'#ffffff', marginBottom:'1vh',  height:'6vh'}}>
            <strong>Detalle Alerta</strong>
        </Grid>
        ) //<p>No hay alerta seleccionada</p>;
    }
  return (
    <Grid container justifyContent={'center'} alignContent={'center'} item xs={11} style={{ width:'15vw',zIndex:6, backgroundColor:'#0d0d0d', borderRadius:'0.5vw', marginBottom:'1vh', border:'solid 0.1vh #7f0000'}}>
      <IconButton onClick={()=>{onClose()}} style={{left:'9.5vw', top:'0.5vh', height:'1.2vw', width:'1.2vw'}}>
        <ClearIcon style={{fontSize:'1vw'}}/>
      </IconButton>
      <Grid container justifyContent={'center'} alignContent={'center'} alignItems={'center'} item xs={9} style={{fontSize:'0.8vw', color:'#ffffff', marginBottom:'1vh',  height:'6vh'}}>
        <strong style={{fontSize:'0.8vw'}}>Detalles de la Alerta </strong>
        {/* Detalles de la Alerta Seleccionada */}
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          Fecha
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh'}}>
          {dia} - {mes} - {año}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          Hora
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh'}}>
          {hora}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          Módulo
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh'}}>
          {modulo}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          Equipo
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh'}}>
          {jaula}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          Sensor
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh'}}>
          {getSensorName(sensor)}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          Valor
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh'}}>
          {alertaSeleccionada.valor.toFixed(1)} {getSensorUnit(sensor)}
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Grid item xs={4} style={{fontSize:'0.8vw', height:'3vh'}}>
          Tipo
        </Grid>
        <Grid item xs={8} style={{fontSize:'0.8vw', height:'3vh',}}>
          <Grid container justifyContent={'center'} style={{borderRadius:'0.2vw',backgroundColor:getBackgroundColor(alertaSeleccionada.rango)}}>
            {getSensorTipo(alertaSeleccionada.rango)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AlertasActivas;