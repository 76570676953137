// import React,{useState} from 'react'
// import { Grid} from '@mui/material'
// import { styled } from '@mui/material/styles';

// import BombaStatusPS from '../PS/BombaStatusPS'
// import BombaStatusVDF from '../VDF/BombaStatusVDF'
// import DetalleCan from './DetalleCan';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import Bomba from '../Common/Bomba';

// import './utils/css/Main_Bombas.css'

// const StyleTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} arrow classes={{ popper: className }} />
//   ))(({ theme }) => ({
//     [`& .${tooltipClasses.arrow}`]: {
//       color: theme.palette.common.black,
//     //   borderLeft: '0.01vh solid white',

//     },
//     [`& .${tooltipClasses.tooltip}`]: {
//       backgroundColor: '#161616',
//     //   border: '0.01vh solid white',
//       fontSize:"0.5vw"
      
//     },
//   }));

// const Main_Bombas = ({translate, modulo, modo,controlType, cantidad_modulos, currentPage, canPorPagina}) => {
  
//   // const [controlType, setControlType] = useState('PS'); // Estado para controlar la vista para //* -- VARIADOR DE FRECUENCIA -- || -- PARTIDOR SUAVE --

//   return (
//     <Grid container  spacing={1} width={"87.5vw"}  style={{}}>
//       {modulo && modulo.cans && modulo.cans.map((can, idx) => {
      
//         return(
//           <Grid container justifyContent="center" alignContent='center' item xs={4} key={idx} className='contenido_can'>
//             <Grid container justifyContent="center" alignContent='center' style={{ height:'29.5vh', marginTop:'-1.5vh'}}>
//               {can.bombas.map((bomba,idx_bomba) => (
//                 <Grid container justifyContent="space-evenly" item xs={6} key ={idx_bomba}>
//                   <Bomba
//                     modulo={modulo}
//                     bomba={bomba} 
//                     color={'#161616'} 
//                     translate={translate}
//                     idx_bomba={idx_bomba}
//                     controlType={controlType} 
//                     modo={modo}
//                   />
//                 </Grid>
                
//               ))}
//             </Grid>
//             <Grid container justifyContent="center" item xs={12}
//                 style={{ marginTop: "-0.5vh", }}>
//                   {/* DETALLES DE LA HF -- CAN -- */}
//                     <DetalleCan
//                         StyleTooltip={StyleTooltip}
//                         idx={idx}
//                         can={can}
//                         translate={translate}
//                     />
//             </Grid>
//           </Grid>
//         )
//       })}
//    </Grid>
 
//   )
// }

// export default Main_Bombas
import React,{useState, useEffect} from 'react'
import { Grid, IconButton } from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

import PaginationButtons from '../PaginationButtons'
import DetalleCan from '../layout/DetalleCan'
import Bomba from '../Common/Bomba'

import './utils/css/Main_Bombas.css'

const Main_Bombas = ({translate, modulo, modo,controlType, cantidad_modulos }) => {


    const [modulePages, setModulePages] = useState(1);
    const handleModulePageChange = (moduleId, pageNumber) => {
        setModulePages(prevModulePages => ({
            ...prevModulePages,
            [moduleId]: pageNumber
        }));
    };

     // Cálculo de jaulas por página
    let cansPorPagina 

    if (cantidad_modulos === 1) {
        cansPorPagina = 6;
    } else if (cantidad_modulos === 2) {
        cansPorPagina = 3;
    }

    // Calcula el número total de páginas
    const totalPages = ((modulo?.cans?.length || 0));


    const [currentPage, setCurrentPage]   = useState (1);
    const [canPorPagina, setcanPorPagina] = useState (cansPorPagina);

     // Estado para manejar el orden de las bombas
    const [isReversed, setIsReversed] = useState(false);
     // Estado para el orden por fila # Centro Tren
    const [isRowOrder, setIsRowOrder] = useState(false);

    // Cargar el estado del localStorage al inicio
    useEffect(() => {
      const storedOrder = localStorage.getItem('bombasOrder');
      if (storedOrder) {
        setIsReversed(JSON.parse(storedOrder));
      }
    }, []);

    // Función para cambiar el orden
    const toggleOrder = () => {
      setIsReversed(prevState => {
        const newOrder = !prevState;
        localStorage.setItem('bombasOrder', JSON.stringify(newOrder)); // Guardar el estado en localStorage
        return newOrder;
      });
    };

    useEffect(() => {
      const storedRowOrder = localStorage.getItem('bombasRowOrder');
      if (storedRowOrder) {
        setIsRowOrder(JSON.parse(storedRowOrder));
      }
    }, []);
  
    const toggleRowOrder = () => {
      setIsRowOrder(prevState => {
        const newOrder = !prevState;
        localStorage.setItem('bombasRowOrder', JSON.stringify(newOrder));
        return newOrder;
      });
    };

// console.log('cantidad_modulos',cantidad_modulos)
  return (
    <Grid container justifyContent="center" alignContent='center' >
        <Grid container justifyContent="center" alignContent='center' className='contenido_modulo_paginas' >
            <PaginationButtons 
                currentPage={currentPage}
                handleModulePageChange={handleModulePageChange} 
                modulo={modulo} 
                totalPages={totalPages}
                canPorPagina={canPorPagina}
                setCurrentPage = {setCurrentPage} 
            />        
        </Grid>
         {/* Botón para cambiar el orden */}
        <IconButton onClick={toggleOrder} style={{ position:'absolute', left:'1vw', top:'10vh', transition:'transform .5s', height:'4.5vh', width:'2.55vw' }}
                    onMouseEnter={(e) => e.target.style.transform = 'scale(1.2)'} // Aumenta el tamaño al pasar el cursor
                    onMouseLeave={(e) => e.target.style.transform = 'scale(1)'} // Vuelve al tamaño original al quitar el cursor
        >
          <SwapVertIcon style={{fontSize:'1.5vw'}}/>
        </IconButton>
         {/* Botón para cambiar el orden por fila o por columna */}
          {/* <IconButton onClick={toggleRowOrder} style={{ position:'absolute', left:'2.5vw', top:'10vh', transition:'transform .5s', height:'4.5vh', width:'2.55vw' }}
                    onMouseEnter={(e) => e.target.style.transform = 'scale(1.2)'} // Aumenta el tamaño al pasar el cursor
                    onMouseLeave={(e) => e.target.style.transform = 'scale(1)'} // Vuelve al tamaño original al quitar el cursor
        >
          <ViewColumnIcon  style={{fontSize:'1.5vw'}}/>
        </IconButton>  */}
        <Grid container justifyContent={modulo?.cans?.length >= 3 ? "center" : "flex-start"}  alignItems={'center'}
            alignContent={"center"} item xs={12}  //style={{marginTop: canPorPagina === 3 ? '-6vh' : '0vh'}}  
            className={`contenido_modulo ${cantidad_modulos > 1 ? 'mayor' : 'menor'}`}>
          {/* { modulo?.cans?.slice((currentPage - 1) * canPorPagina,
                currentPage * canPorPagina).map((can, idx) => (
            <Grid container justifyContent="center" alignContent='center' item xs={4} key={idx}  className={`contenido_can ${cantidad_modulos > 1 ? 'mayor' : 'menor'} ${modulo?.cans?.length > 3 ? 'can_mayor' : 'can_menor'}`}>
                <Grid container justifyContent="center" style={{ height:'29.5vh', marginTop:'-1.5vh'}} >
                  {can?.bombas?.map((bomba , idx_bomba) => {
                    return(
                      <Grid container justifyContent="center"  alignContent='center' flexDirection="column" key={idx_bomba} item xs={6} >
    
                            <Bomba
                                modulo={modulo}
                                bomba={bomba} 
                                color={'#161616'} 
                                translate={translate}
                                idx_bomba={idx_bomba}
                                controlType={controlType}
                                modo={modo}
                            />

                      </Grid>
                      
                    )
                  })}            
                </Grid>
                <Grid container justifyContent="center" item xs={12}
                    style={{ marginTop: "0.5vh", marginLeft:'0vw'}}
                    >
                        <DetalleCan
                          // StyleTooltip={StyleTooltip}
                          idx={idx}
                          can={can}
                          translate={translate}
                        />
                </Grid>
            </Grid>
          ))} */}
            {modulo?.cans?.slice((currentPage - 1) * canPorPagina, currentPage * canPorPagina).map((can, idx) => (
              <Grid container justifyContent="center" alignContent="center" item xs={isRowOrder === true ? 6 : 4} key={idx} 
                className={`contenido_can ${modulo?.cans?.length > 3 ? 'can_mayor' : 'can_menor'} 
                          ${isRowOrder === true ? can?.bombas.length === 1 ? 'oneCan' : can?.bombas.length === 2 ? 'twoCan' : can?.bombas.length === 3 ? 'threeCan' : 'fourCan'   : 'columna'} `}>
                <Grid container justifyContent="center" flexDirection={'row'} style={{ height: '29.5vh', marginTop: '-1.5vh' }}>

              {/* { isRowOrder &&
                      <Grid container item xs={12} justifyContent="center">
                        {can?.bombas?.map((bomba, idx_bomba) => {
                        
                            // Si estamos en el modo de orden por fila, mostramos todas las bombas en una fila
                            return (
                              <Grid container justifyContent="center" alignContent="center" key={idx_bomba} item xs={3}>
                                <Bomba
                                  modulo={modulo}
                                  bomba={bomba}
                                  color={'#161616'}
                                  translate={translate}
                                  idx_bomba={idx_bomba}
                                  controlType={controlType}
                                  modo={modo}
                                  isRowOrder={isRowOrder}
                                />
                              </Grid>
                            );
                        })}
                      </Grid>
                    }  */}
                    {!isRowOrder && 
                      <>
                        {/* Fila para índices pares o impares dependiendo del orden */}
                        <Grid container item xs={12} justifyContent="center">
                          {can?.bombas?.map((bomba, idx_bomba) => {
                            if ((isReversed && bomba.alias % 2 !== 0) || (!isReversed && bomba.alias % 2 === 0)) {
                              return (
                                <Grid container justifyContent="center" alignContent="center" key={idx_bomba} item xs={6}>
                                  <Bomba
                                    modulo={modulo}
                                    bomba={bomba}
                                    color={'#161616'}
                                    translate={translate}
                                    idx_bomba={idx_bomba}
                                    controlType={controlType}
                                    modo={modo}
                                    isRowOrder={isRowOrder}

                                  />
                                </Grid>
                              );
                            }
                            return null;
                          })}
                        </Grid>

                        {/* Fila para índices opuestos (pares o impares dependiendo del orden) */}
                        <Grid container item xs={12} justifyContent="center">
                          {can?.bombas?.map((bomba, idx_bomba) => {
                            if ((isReversed && bomba.alias % 2 === 0) || (!isReversed && bomba.alias % 2 !== 0)) {
                              return (
                                <Grid container justifyContent="center" alignContent="center" key={idx_bomba} item xs={6}>
                                  <Bomba
                                    modulo={modulo}
                                    bomba={bomba}
                                    color={'#161616'}
                                    translate={translate}
                                    idx_bomba={idx_bomba}
                                    controlType={controlType}
                                    modo={modo}
                                    isRowOrder={isRowOrder}

                                  />
                                </Grid>
                              );
                            }
                            return null;
                          })}
                        </Grid>
                      </>
                    }
                  
                    
                </Grid>
                <Grid container justifyContent="center" item xs={12} style={{ marginTop: "0.5vh", marginLeft: '0vw' }}>
                  <DetalleCan
                    idx={idx}
                    can={can}
                    translate={translate}
                  />
                </Grid>
              </Grid>
            ))}
        </Grid>
    </Grid>
  )
}

export default Main_Bombas
