import React from 'react';
import {Grid,IconButton,Typography, Tooltip, ToggleButtonGroup, ToggleButton} from '@mui/material';
import LogoNaranjo from '../../assets/img/ITG-Logotipo-SobreNegro.png'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ModalCalendario from './modal/ModalCalendario';
import Historial_Control from './modal/Historial_Control';
import TranslateIcon from '@mui/icons-material/Translate';
import Traductor from '../../../../components/Traductor';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TemporaryDrawer from './TemporaryDrawer'; 
import Nanoox from '../../assets/img/APP_Nanoox_sin fondo.png'
import LastMeasurement from './utils/LastMeasurement'
import './utils/css/MyAppbar.css'
import GenerateGREEN from './utils/img/generateGREEN.png'
import GenerateGRAY from './utils/img/generateGRAY.png'
import GenerateRED from './utils/img/generateRED.png'
import GenerateYELLOW from './utils/img/generateYELLOW.png'

import WindowOpener from '../../../../utils/WindowOpener';

export function MyAppBar({
    controlType,
    nameCentro,
    translate ,
    handleClick ,
    openMenuIdioma ,
    handleCloseIdioma ,
    handleLanguageChange, 
    idioma,
    ultimaHora,notificationOpen, setNotification_open, setNotification_msg, setNotification_status,
    setNameCentro,
    SWVersion,
    centroSeleccionado,
    regionSeleccionada,
    handleCentroClick,
    areaSeleccionada,
    handleAreaClick,
    theme,
    handleRegionClick,
    capitalize,
    consolidado_estados,
    selectedRegion, setSelectedRegion,
    selectedArea, setSelectedArea,
    setMostrarAlertas,
    mostrarAlertas,
    modo, setModo,
    center_data,
    otherWindowsIndex, setOtherWindowsIndex,
      }) {

  const [openModal, setOpenModal] = React.useState(false)//CALENDARIO
  const [open, setOpen] = React.useState(true);

  const plc_status = center_data?.generadores?.plc_status
  const hf_status  = center_data?.generadores?.hf_status

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  //  * --------------------------handle MouseEnter
  const handleLogoMouseEnter = () => {
    setOpen(true); 
  };
    
  const handleLogoMouseLeave = () => {
      setOpen(false); 
  };


  const MyDrawerProps = {
    SWVersion: SWVersion,
    theme: theme,
    handleAreaClick: handleAreaClick,
    handleRegionClick: handleRegionClick,
    areaSeleccionada: areaSeleccionada,
    handleCentroClick: handleCentroClick,
    regionSeleccionada: regionSeleccionada,
    centroSeleccionado: centroSeleccionado,
    setNameCentro: setNameCentro,
    translate: translate,
    capitalize:capitalize,
    consolidado_estados:consolidado_estados,
    selectedRegion:selectedRegion, 
    setSelectedRegion:setSelectedRegion,
    selectedArea:selectedArea, 
    setSelectedArea:setSelectedArea,

    open:open,
    setOpen:setOpen,
    toggleDrawer:toggleDrawer,
    handleLogoMouseEnter:handleLogoMouseEnter, 
    handleLogoMouseLeave:handleLogoMouseLeave,
    setMostrarAlertas:setMostrarAlertas,
    mostrarAlertas:mostrarAlertas

  } 

    // mensaje tooltip 
    let mensaje;
    let imagenSrc;
    let generadorCss;


    if (center_data.generadores?.plc_status === 100) {
      mensaje = "Alarma en Generador";
      imagenSrc = GenerateRED;
      generadorCss=""

    } else if (center_data.generadores?.plc_status === -1) {
      mensaje = "Sin Conexión Generador";
      imagenSrc = GenerateYELLOW;
      generadorCss=""
      
    } else if (center_data.generadores?.plc_status === 60) {
      mensaje = "Comunicación con Generador";
      imagenSrc = GenerateGREEN;
      if (center_data.generadores?.partida === 100 && center_data.generadores?.detencion === 60) {
        generadorCss = 'Generador Encendido';
      } else {
        generadorCss = 'Generador Apagado';
      }
    } else {
      mensaje = "Sin Información Generador";
      imagenSrc = GenerateGRAY;
      generadorCss=""

    }


  return (
    // <>
    // <AppBar position="fixed"  style={{height:"8vh"}}>
      <Grid container justifyContent={'center'} style={{height:"8vh", marginTop:'0vh',borderBottom:'solid 0.1vh #DE4E11 '}}>
        <Grid container justifyContent='center' alignContent='center' item xs={11} style={{}}>
            <Grid container justifyContent='center' alignContent='center'  alignItems={'center'} item xs={1}>
              <IconButton
                color="inherit"
                onClick={toggleDrawer(true)}
                edge="start"
                onMouseEnter={() => handleLogoMouseEnter()}
                onMouseLeave={() => handleLogoMouseLeave()}
                style={{position: 'fixed',}}
              >
                <img
                    src={LogoNaranjo}
                    alt="Icono"
                    style={{ width: "3.5vw", aspectRatio: '4/3', marginLeft: "1vw",  color:"white" }}
                />
              </IconButton> 
            </Grid>
             {/* Botón Calendario */}
            <Grid container justifyContent='center' alignContent='center' item xs={1} >
                    <IconButton onClick={() => setOpenModal(!openModal)} style={{ position: 'fixed',  width: "2.8vw", height: "6vh",  marginTop:'0.8vh', marginLeft: '-0.25vw' }} >
                        <CalendarMonthIcon style={{fontSize:"1.75vw",color: "whitesmoke", /* position: 'fixed', top: '1.3vh', left: '10vw' */ }} />
                    </IconButton>
                    <ModalCalendario controlType={controlType} openModal={openModal} setOpenModal={setOpenModal} idioma={idioma} translate={translate} notificationOpen={notificationOpen} setNotification_open={setNotification_open} setNotification_msg={setNotification_msg} setNotification_status={setNotification_status} />
                    <Tooltip title="Historial de Control">
                      <Historial_Control center_data={center_data} /> {/* Historial de control */}
                    </Tooltip>
            </Grid>

            {/* Botón historial de control */}

            {/* <Grid container justifyContent={'center'} alignContent={'center'} item xs={1} >
             

            </Grid> */}

            <Grid  container justifyContent={'center'} alignContent={'center'} item xs={2} style={{zIndex:10}} >
                <Tooltip title="Tipo de Vista">
                    {/* <div className="radio-input">
                        <label className="label" htmlFor="simple">
                            <input
                            type="radio"
                            id="simple"
                            name="value-radio"
                            value="simple"
                            checked={modo === true}
                            onChange={() => setModo(true)}
                            />
                            <span className="text">Simple</span>
                        </label>
                        <label className="label" htmlFor="completa">
                            <input
                            type="radio"
                            id="completa"
                            name="value-radio"
                            value="completa"
                            checked={modo === false}
                            onChange={() => setModo(false)}
                            />
                            <span className="text">Detallada</span>
                        </label>
                        </div> */}
                         <ToggleButtonGroup
                          height='3.9vh'
                          color="standard"
                          exclusive
                          value={modo ? "simple" : "completa"}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              setModo(newValue === "simple");
                            }
                          }}
                          aria-label="Platform"
                        >
                          <ToggleButton style={{width:'5vw', height:'3.9vh', fontSize:'0.6vw'}} value="simple">Simple</ToggleButton>
                          <ToggleButton style={{width:'5vw', height:'3.9vh', fontSize:'0.6vw'}} value="completa">Detallada</ToggleButton>
                        </ToggleButtonGroup>
                    </Tooltip>
               </Grid>
           {/* <Grid container justifyContent='center' alignContent='center' item xs={1} border={'solid'}>
              Botón abrir ventanas
              <WindowOpener windowIndex={otherWindowsIndex} setWindowIndex={setOtherWindowsIndex} />
            </Grid> */}
            <Grid container justifyContent='center' alignContent='center' item xs={3} >
                    {nameCentro && (
                        <Grid container  style={{ alignItems: 'center', justifyContent: 'center' }}>
                          <Grid container justifyContent='center'>
                              <Typography style={{ fontSize: '0.75vw', fontWeight: 100 }}>{translate('accessed_center')}</Typography>
                          </Grid>
                          <Grid container justifyContent='center'>
                              <Typography style={{ fontSize: '1vw', fontWeight: 700 }}>{capitalize(nameCentro)}</Typography>
                          </Grid>
                        </Grid>
                    )}
            </Grid>
            
            {/* idiomas */}
            <Grid container justifyContent='center' alignContent='center' item xs={1}>
                <IconButton  onClick={handleClick} style={{marginRight:'1vw'}}>
                  <TranslateIcon style={{fontSize:'1.1vw', color:'#e84e0e'}}/>
                </IconButton>
                {/* Menú de idiomas */}
                <Traductor handleLanguageChange ={handleLanguageChange} openMenuIdioma ={openMenuIdioma} handleCloseIdioma ={handleCloseIdioma} />
            </Grid>
            {/* generadores */}
            <Grid container justifyContent='center' alignContent='center' item xs={1}>
            <Tooltip title={mensaje}
                // { ( plc_status === 60 && hf_status === 200) ? "Comunicación con el Generador" : (plc_status === 100) ? "Alerta Generador" : ((plc_status === -1 || hf_status === -2) ? "Sin Conexión Generador" : "Sin Información Generador") }
              >
                {/* <img src={Generate} alt="Icono" className={`generator-icon ${false ? "active" : "inactive"}`} /> */}
                <img  src={imagenSrc} className={`generator-icon`}/>
                {/* // { (plc_status === 60 && hf_status === 200) ? GenerateGREEN : (plc_status === 100) ? GenerateRED : ((plc_status === -1 || hf_status === -2) ? GenerateYELLOW : GenerateGRAY) } alt="Icono" className={`generator-icon ${plc_status === 60 || hf_status=== 200 ? "active" : plc_status === 100 ? "inactive": "active"}`} /> */}
              </Tooltip>
              <p style={{position: 'absolute',marginLeft: '0vw', marginTop: '5.5vh', fontSize: '0.5vw'}}>{generadorCss}</p>
            </Grid>
            <Grid container justifyContent='center' alignContent='center' item xs={3}  >
              <LastMeasurement ultimaHora={ultimaHora}/>
            </Grid>
        </Grid>
        <Grid container justifyContent="center" alignContent='center' item xs={1} style={{ marginLeft:"-1vw"}}   >
              <img
                  src={Nanoox}
                  alt="Icono"
                  style={{ width: '4.8vw', aspectRatio:'4/2', marginTop:'0.5vh',  color:"white",}}
              />
        </Grid>
        {/* Drawer */}
        <TemporaryDrawer  {...MyDrawerProps} />
      </Grid>

      
    // </AppBar>
  
      // </>
  );
}
